// Warframe.com - Warframe 1999 Theme
// Common theme for all WF 1999 hubsite pages

@font-face {
    font-family: 'W95FA';

    src: url('/fonts/w95fa.woff');
    src: url('/fonts/w95fa.woff2');
}

$GLITCH_ANIMATION_ANGLE: 170deg;

@mixin character-card-glitch-frame-0 {
    filter: opacity(0);
    transform: scale(1);
    mask-image: none;
}
@mixin character-card-glitch-frame-1 {
    filter: opacity(0.4);
    transform: scale(1.05);
    mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 30%, black 31%, black 39%, transparent 40%);
}
@mixin character-card-glitch-frame-2 {
    filter: opacity(0.6);
    transform: scale(1.05);
    mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 60%, black 61%, black 89%, transparent 90%);
}
@mixin character-card-glitch-frame-3 {
    filter: opacity(0.8);
    transform: scale(1.05);
    mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, black 34%, transparent 35%);
}
@mixin character-card-glitch-frame-4 {
    filter: opacity(1);
    transform: scale(1);
    mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 50%, black 51%);
}
@mixin character-card-glitch-frame-5 {
    filter: opacity(1);
    transform: scale(1);
    mask-image: none;
}

@keyframes --character-card-glitch-in {
    0% {
        @include character-card-glitch-frame-0;
    }
    20% {
        @include character-card-glitch-frame-1;
    }
    40% {
        @include character-card-glitch-frame-2;
    }
    60% {
        @include character-card-glitch-frame-3;
    }
    80% {
        @include character-card-glitch-frame-4;
    }
    100% {
        @include character-card-glitch-frame-5;
    }
}
@keyframes --character-card-glitch-out {
    0% {
        @include character-card-glitch-frame-5;
    }
    20% {
        @include character-card-glitch-frame-4;
    }
    40% {
        @include character-card-glitch-frame-3;
    }
    60% {
        @include character-card-glitch-frame-2;
    }
    80% {
        @include character-card-glitch-frame-1;
    }
    100% {
        @include character-card-glitch-frame-0;
    }
}

@keyframes --character-card-glitch-corner {
    0% {
        filter: opacity(0);
        transform: scale(1);
        mask-image: none;
    }
    10% {
        filter: opacity(0.3);
        transform: scale(1.03);
        mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 85%, black 86%);
    }
    12% {
        filter: opacity(0.1);
        transform: scale(1.05);
        mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 88%, black 89%, black 95%, transparent 96%);
    }
    14% {
        filter: opacity(0.4);
        transform: scale(1.04);
        mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 92%, black 93%, black 94%, transparent 95%);
    }
    16% {
        filter: opacity(0.2);
        transform: scale(1.02);
        mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 81%, black 82%, black 88%, transparent 89%, transparent 91%, black 92%, black 98%, transparent 99%);
    }
    18% {
        filter: opacity(0.5);
        transform: scale(1.05);
        mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 85%, black 86%, black 90%, transparent 91%);
    }
    20% {
        filter: opacity(0);
        transform: scale(1);
        mask-image: none;
    }
}

@keyframes --character-card-glitch-corner-fast {
    0% {
        filter: opacity(0);
        transform: scale(1);
        mask-image: none;
    }
    20% {
        filter: opacity(0.3);
        transform: scale(1.03);
        mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 85%, black 86%);
    }
    40% {
        filter: opacity(0.1);
        transform: scale(1.05);
        mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 88%, black 89%, black 95%, transparent 96%);
    }
    60% {
        filter: opacity(0.4);
        transform: scale(1.04);
        mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 92%, black 93%, black 94%, transparent 95%);
    }
    80% {
        filter: opacity(0.2);
        transform: scale(1.02);
        mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 81%, black 82%, black 88%, transparent 89%, transparent 91%, black 92%, black 98%, transparent 99%);
    }
    100% {
        filter: opacity(0.5);
        transform: scale(1.05);
        mask-image: linear-gradient($GLITCH_ANIMATION_ANGLE, transparent 85%, black 86%, black 90%, transparent 91%);
    }
}

@keyframes --paperclip-flip {
    0% {
        transform: translate(50%, -50%);
    }
    50% {
        transform: translate(70%, -100%) scale(0.9) rotate(-2.5deg);
    }
    100% {
        transform: translate(80%, -58%) scale(0.8) rotate(-5deg);
    }
}
@keyframes --paperclip-unflip {
    0% {
        transform: translate(80%, -58%) scale(0.8) rotate(-5deg);
    }
    50% {
        transform: translate(70%, -100%) scale(0.9) rotate(-2.5deg);
    }
    100% {
        transform: translate(50%, -50%);
    }
}

@keyframes --live-blink {
    0% {
        background-color: #F00;
    }
    50% {
        background-color: #600;
    }
}

@keyframes --lightbox-button-shimmer {
    0% {
        transform: translateX(0);
        filter: opacity(0);
    }
    10% {
        transform: translateX(100%);
        filter: opacity(1);
    }
    20% {
        transform: translateX(200%);
        filter: opacity(0);
    }
    100% {
        transform: translateX(200%);
        filter: opacity(0);
    }
}

$COLOR_1999_TITLE: #FFC32B;
$COLOR_1999_TRIM: #C09C67;
$COLOR_CYTE09_TITLE: #FC7D0E;

$COLOR_1999_MASTHEAD_TITLE: $COLOR_1999_TITLE;
$COLOR_1999_MASTHEAD_BORDER: fade-out($COLOR_1999_TRIM, 0.25);

$COLOR_1999_HUB_BACKGROUND_TOP: #030404;
$COLOR_1999_HUB_BACKGROUND_BOTTOM: #263A3C;

@import "../style-guide/_variables";
@import "../style-guide/_responsive";
@import "../style-guide/_mixins";
@import "../style-guide/_typography";
@import "includes/_1999-shared";

$COLOR_1999_BORDER_MEDIA: fade-out($COLOR_BACKGROUND, 0.75);
$COLOR_1999_BORDER_MEDIA_HOVER: $COLOR_BACKGROUND;
$COLOR_SCALDRA_BORDER: #96D610;
$FONT_FAMILY_SERIF: "Times New Roman", Times, serif;
$FONT_FAMILY_MONOSPACE: "Courier Prime", "Courier New", monospace;

$IMAGE_DIR: '/images/promo/1999/';

// Hub home styling
#warframe1999 {
    background: linear-gradient(to bottom, $COLOR_1999_HUB_BACKGROUND_TOP 0%, $COLOR_1999_HUB_BACKGROUND_BOTTOM 100%);
    color: $COLOR_INVERTED_TEXT;

    --color-text: #{$COLOR_INVERTED_TEXT};
    --color-title: #{$COLOR_INVERTED_TITLE};
    --color-masthead-title: #{$COLOR_1999_MASTHEAD_TITLE};
    --color-masthead-border: #{$COLOR_1999_MASTHEAD_BORDER};

    .Masthead-inner {
        @include CONTENT_CONTRAST_FILTER;
    }


    .SectionTitle {
        color: var(--color-title);
        @include chromaticAberration;
        @include TYPOGRAPHY_H1_ALTERNATE;

        &--standalone {
            text-align: center;
            margin-bottom: $SPACE_LARGE;
        }
    }

    .Button--retro {
        --color-button-background: #BDBDBD;
        --color-button-text: #{$COLOR_BLACK};
        --color-button-background-hover: #BDBDBD;
        --color-button-text-hover: #{$COLOR_BLACK};

        border-radius: 0;
        border: solid 2px rgba($COLOR_WHITE, 0.5);
        border-right-color: rgba($COLOR_BLACK, 0.5);
        border-bottom-color: rgba($COLOR_BLACK, 0.5);
        &:hover,
        &:active {
            border: solid 2px rgba($COLOR_WHITE, 0.75);
            border-right-color: rgba($COLOR_BLACK, 0.75);
            border-bottom-color: rgba($COLOR_BLACK, 0.75);
        }
    }

    .RibbonLabel {
        position: absolute;
        top: $SPACE_LARGER;
        left: $CONTENT_MARGIN_MOBILE;
        transform: rotate(-1deg);

        font-family: $FONT_FAMILY_MONOSPACE;
        font-weight: 700;
        line-height: 1.5;
        text-transform: uppercase;
        color: $COLOR_WHITE;

        padding: $SPACE_SMALL $SPACE_MEDIUM;
        background-color: $COLOR_BLACK;
        filter: drop-shadow($SPACE_SMALLER $SPACE_SMALLER 0 rgba($COLOR_BLACK, 0.5));

        @include breakpoint-tablet-up {
            left: $CONTENT_MARGIN_TABLET;
        }
        @include breakpoint-desktop-up {
            left: $CONTENT_MARGIN_DESKTOP;
        }
        @include breakpoint-wide-up {
            left: $CONTENT_MARGIN_WIDE;
        }

        &[data-id="protoframes"] {
            @include breakpoint-mobile-only {
                top: 32vw;
            }
        }
        &[data-id="warframe"] {
            @include breakpoint-mobile-only {
                top: 70vw;
                transform: translateY(-100%) rotate(-1deg);
            }
            @include breakpoint-tablet-up {
                top: 65vw;
                transform: translateY(-100%) rotate(-1deg);
            }
            @include breakpoint-desktop-up {
                transform: rotate(-1deg);
                top: $SPACE_LARGER;
                left: auto;
                right: $CONTENT_MARGIN_DESKTOP;
            }
            @include breakpoint-wide-up {
                left: auto;
                right: $CONTENT_MARGIN_WIDE;
            }
        }
        &[data-id="locations"] {
            background-color: #F45D94;
            filter: drop-shadow($SPACE_SMALLER $SPACE_SMALLER 0 rgba(#F45D94, 0.5));

            @include breakpoint-mobile-only {
                top: 48px;
            }
        }
    }

    .MastheadBackground {
        @include FULL_COVER;
        background: center / cover no-repeat url(#{$IMAGE_DIR}hub-masthead-poster.jpg);
    }
    .MastheadVideo {
        @include FULL_COVER;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .Masthead-inner {
        @include breakpoint-desktop-up {
            height: 1100px;
        }
    }
    .Masthead-buttons {
        @include breakpoint-desktop-up {
            padding-bottom: 120px;
        }
    }
    .MastheadButton {
        &--secondary {
            --color-button-border: #{$COLOR_BUTTON_SECONDARY_INVERTED_BORDER_HOVER};
        }
    }

    .HomeMastheadTileSectionWrapper {
        position: relative;
        z-index: 2;

        @include breakpoint-tablet-down {
            background-color: $COLOR_BLACK;
        }
        @include breakpoint-desktop-up {
            height: 0;
        }
    }
    .HomeMastheadTileSection {
        position: relative;

        @include breakpoint-desktop-up {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    .HomeMastheadTileGrid {
        grid-template-columns: 1fr;
        gap: $GRID_GAP;
        padding-top: $SPACE_LARGE;
        padding-bottom: $SPACE_LARGE;

        @include breakpoint-desktop-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        &.is-expanded {
            @include breakpoint-desktop-up {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        }
    }

    .HomeMastheadTile {
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: center;
        gap: $GRID_GAP;
        background-color: rgba(#0D0913, 0.82);
        padding: $SPACE_LARGE;

        @include breakpoint-mobile-only {
            padding: $GRID_GAP;
        }
        @include breakpoint-desktop-only {
            padding: $GRID_GAP;
        }

        &-image {
            aspect-ratio: 444/250;
            background: rgba($COLOR_WHITE, 0.5) center / cover no-repeat;

            &[data-id="demo"] {
                background-image: url(#{$IMAGE_DIR}masthead-tile-nin-trailer.jpg);
            }
            &[data-id="protoframe"] {
                background-image: url(#{$IMAGE_DIR}masthead-tile-protoframe-trailer.jpg);
            }
            &[data-id="anime"] {
                background-image: url(#{$IMAGE_DIR}masthead-tile-anime.jpg);
            }
        }
        &-text {
            color: $COLOR_INVERTED_TEXT;
        }
        &-title {
            display: flex;
            align-items: center;
            gap: $SPACE_SMALL;

            font-weight: bold;
            text-transform: uppercase;
        }
        &-icon {
            vertical-align: bottom;
            filter: invert(1);
        }
    }

    .TGAStreamBackground {
        @include FULL_COVER;

        background: top center / cover no-repeat url(#{$IMAGE_DIR}bg-hub-tga.jpg);

        @include breakpoint-mobile-only {
            bottom: auto;
            aspect-ratio: 4/3;
            background-position: left;
            mask-image: linear-gradient(to bottom, black 80%, transparent);
        }
    }
    .TGAStreamSection {
    }
    .TGAStreamGrid {
        position: relative;
        padding-top: $SPACE_LARGE;
        padding-bottom: $SPACE_LARGE;
        border-top: solid 1px rgba(#D1753A, 0.5);
        border-bottom: solid 1px rgba(#D1753A, 0.5);

        @include breakpoint-tablet-up {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            align-items: center;
            gap: $GRID_GAP;
            padding-left: 20%;
        }

        &-text {
            color: $COLOR_INVERTED_TEXT;
        }
        &-title {
            @include TYPOGRAPHY_H5_ALTERNATE;

            @include breakpoint-mobile-only {
                padding-top: 60vw;
            }
        }
        &-image {
            aspect-ratio: 16/9;
            background: center / cover no-repeat url(#{$IMAGE_DIR}card-tga.jpg);

            @include breakpoint-mobile-only {
                margin-top: 1em;
            }
        }
        &-actions {
            justify-content: flex-start;
        }
        &-action {
            display: inline-flex;
            flex-flow: row nowrap;
            justify-content: center;
            gap: $SPACE_SMALLER;
        }
        .Icon {
            filter: invert(1);
        }
    }

    .PromoSection {
    }
    .PromoBackground {
    }
    .GetReadyGrid {
        position: relative;
        margin: 100px auto;

        &-title {
            text-align: center;
            margin-bottom: $SPACE_LARGE;
        }

    }
    .GetReadyCard {
        --card-border-color: transparent;
        --card-background: #{rgba(#04080D, 0.5)};

        .Card-content {
            display: grid;
            grid-template-rows: auto 1fr auto;
        }

        .Button--secondary {
            --color-button-border-hover: $COLOR_WHITE;
        }

        &-title {
            color: $COLOR_1999_TITLE;
            @include TYPOGRAPHY_H4;
        }
        &-description {
            color: $COLOR_INVERTED_TEXT;
        }
    }

    .ProtoframesBackground {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 20vw;

        background: top / cover no-repeat url(#{$IMAGE_DIR}bg-hub-protoframes-section.jpg);
        mask-image: linear-gradient(to bottom, black 50%, transparent);
    }
    .ProtoframesGrid {
        position: relative;
        padding-top: 8vw;
        padding-bottom: $SECTION_VERTICAL_PADDING_MOBILE;

        @include breakpoint-tablet-up {
            padding-bottom: $SECTION_VERTICAL_PADDING_TABLET;
        }

        &-title {
            color: $COLOR_INVERTED_TITLE;
            text-align: center;
            margin-bottom: $SPACE_LARGE;

            @include chromaticAberration();
            @include TYPOGRAPHY_H1_ALTERNATE;

            @include breakpoint-desktop-up {
                font-size: 100px;
            }
        }

        &-columns {
            position: relative;
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            align-items: start;
            gap: $GRID_GAP;

            @include breakpoint-tablet-up {
                grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);
            }
        }
        &-column {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            gap: $GRID_GAP;
        }

        &-sticker {
            position: absolute;
            right: 1%;
            top: 0;
            transform: translate(50%, -50%);
            width: 15%;
            aspect-ratio: 316/291;
            background: center / contain no-repeat url(#{$IMAGE_DIR}sticker-goggles.png);
            pointer-events: none;

            @include breakpoint-mobile-only {
                display: none;
            }
        }
    }

    .ProtoframesHexTile {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        color: $COLOR_TEXT;

        &-image {
            background: top / cover no-repeat url(#{$IMAGE_DIR}bg-hub-protoframes.jpg);
            aspect-ratio: 16/9;
        }
        &-text {
            padding: $CARD_CONTENT_PADDING;
            background-color: $COLOR_WHITE;
        }

        &-title {
            @include TYPOGRAPHY_H3_ALTERNATE;

            @include breakpoint-desktop-up {
                font-size: $FONT_SIZE_H1_DESKTOP;
            }
        }
        &-description {
            margin-top: 1em;
            line-height: $LINE_HEIGHT_PARAGRAPH;
        }
    }
    .ProtoframesGeminiTile {
        position: relative;

        background-color: #10213D;
        color: $COLOR_INVERTED_TEXT;

        @include breakpoint-desktop-up {
            border-left: solid 4px #AF363A;
        }

        &-image {
            aspect-ratio: 874/408;
            background: center / cover no-repeat url(#{$IMAGE_DIR}bg-hub-gemini.jpg);

            @include breakpoint-tablet-down {
                width: 100%;
            }
            @include breakpoint-desktop-up {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                mask-image: linear-gradient(to left, black 60%, transparent);
            }
        }

        &-text {
            position: relative;
            padding: $CARD_CONTENT_PADDING;
            @include CONTENT_CONTRAST_FILTER;

            @include breakpoint-desktop-up {
                margin-right: 40%;
            }
        }
        &-title {
            color: #4BE4ED;
            @include TYPOGRAPHY_H3_ALTERNATE;
        }
        &-description {
            margin-top: 1em;
            line-height: $LINE_HEIGHT_PARAGRAPH;
        }
    }
    .ProtoframesRelationshipCard {
        --card-border-color: transparent;
        --card-background: #{$COLOR_WHITE};

        color: $COLOR_TEXT;
        &-title {
            @include TYPOGRAPHY_H3_ALTERNATE;
        }
        &-description {
            margin-top: 1em;
            line-height: $LINE_HEIGHT_PARAGRAPH;
        }
    }
    .ProtoframesComicTile {
        position: relative;
        display: block;
        padding: $CARD_CONTENT_PADDING;

        background-color: $COLOR_WHITE;
        color: $COLOR_TEXT;
        --color-link: #{$COLOR_TEXT};
        --color-link-hover: #{$COLOR_TEXT};

        &-text {
            margin-right: 35%;
            @include breakpoint-tablet-only {
                margin-right: auto;
            }
        }
        &-title {
            @include TYPOGRAPHY_H3_ALTERNATE;
        }
        &-description {
            margin-top: 1em;
            line-height: $LINE_HEIGHT_PARAGRAPH;
        }

        &-cover {
            position: absolute;
            right: 0;
            top: 50%;
            width: 30%;
            aspect-ratio: 400/518;
            transform: translateY(-50%) rotate(7deg);

            background: center / contain no-repeat url(#{$IMAGE_DIR}card-comic.jpg);

            @include breakpoint-tablet-only {
                position: relative;
                top: auto;
                right: auto;
                width: 80%;
                margin: -10% auto $SPACE_SMALL;
                transform: rotate(7deg);
            }
        }
        &-cta {
            text-decoration: underline;
            color: $COLOR_LINK;
        }
    }

    .Cyte09Section {
    }
    .Cyte09Background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100vw;
        background: 15% center / cover no-repeat url(#{$IMAGE_DIR}bg-hub-cyte09.jpg);
        mask: url(/images/mask-chevron-square.svg) center / 100% 100% no-repeat;
        overflow: hidden;

        &-overlay {
            position: absolute;
            right: 0;
            top: 20%;
            height: 150%;
            aspect-ratio: 279/1011;
            background: center / contain no-repeat url(#{$IMAGE_DIR}bg-hub-cyte09-overlay.png);
            opacity: 0.25;
            pointer-events: none;

            @include breakpoint-tablet-down {
                display: none;
            }
        }

        @include breakpoint-tablet-up {
            height: 80vw;
            background-position: left center;
        }
        @include breakpoint-desktop-up {
            height: 56.25vw;
            background-position: center;
        }
        @include breakpoint-wide-up {
            height: 1080px;
        }
    }
    .Cyte09Grid {
        color: $COLOR_INVERTED_TEXT;

        &-header {
            position: relative;

            @include breakpoint-tablet-down {
                display: flex;
                flex-flow: column nowrap;
                justify-content: flex-end;
                align-items: center;
                text-align: center;
                margin: 0 auto;
                @include CONTENT_CONTRAST_FILTER;
            }

            @include breakpoint-desktop-up {
                height: 56.25vw;
            }
            @include breakpoint-wide-up {
                height: 1080px;
            }
        }
        &-panel {
            text-align: center;

            @include breakpoint-desktop-up {
                position: absolute;
                top: 50%;
                right: 0;
                width: 40%;
                transform: translateY(-50%);

                background-color: $COLOR_BLACK;
                border-left: solid $SPACE_SMALL rgba($COLOR_CYTE09_TITLE, 0.25);
                border-bottom-right-radius: $SPACE_MEDIUM;

                padding: $SPACE_LARGE;
                text-align: left;
            }
        }
        &-title {
            color: $COLOR_CYTE09_TITLE;
            @include TYPOGRAPHY_H1_ALTERNATE;
            @include breakpoint-tablet-down {
                height: 80vw;
                display: flex;
                flex-flow: column nowrap;
                justify-content: flex-end;
                padding-bottom: $SECTION_VERTICAL_PADDING_MOBILE;
            }
            @include breakpoint-mobile-only {
                height: 100vw;
            }
        }
        &-subtitle {
            color: $COLOR_CYTE09_TITLE;
            text-align: center;
            @include TYPOGRAPHY_H2_ALTERNATE;
        }
        &-description {
            margin-top: $SPACE_LARGE;
            line-height: $LINE_HEIGHT_PARAGRAPH;
        }
        &-disclaimer {
            text-align: center;
            max-width: 600px;
            margin: 1em auto 0;
        }
        &-fold {
            padding-top: $SECTION_VERTICAL_PADDING_MOBILE;

            @include breakpoint-tablet-up {
                padding-top: $SECTION_VERTICAL_PADDING_TABLET;
            }
        }
        &-abilities {
            margin-top: $SPACE_LARGE;
        }
        &-ability {
            --card-border-color: transparent;
            border-top: solid 1px rgba($COLOR_CYTE09_TITLE, 0.25);
            background: linear-gradient(to bottom, #2F1416, #0E0B0B);

            .Card-media {
                width: 100%;
                aspect-ratio: 4/3;
                border-top: solid 2px #2F1416;
                padding-bottom: 0;
                background-color: $COLOR_BLACK;
                background-size: 60%;
            }
        }

        &-abilityTitle {
            color: $COLOR_CYTE09_TITLE;
            @include TYPOGRAPHY_H5;
        }
        &-abilityDescription {
            font-size: $FONT_SIZE_PARAGRAPH_SMALL;
        }
    }

    .ReworkBackground {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        aspect-ratio: 122/756;
        background: left top / contain no-repeat url(#{$IMAGE_DIR}bg-hub-reworks-overlay.png);

        @include breakpoint-mobile-only {
            display: none;
        }
    }
    .ReworkSection {
        --color-text: #{$COLOR_INVERTED_TEXT};
        color: #{$COLOR_INVERTED_TEXT};

        h3, .h3 {
            --color-title: #{$COLOR_GOLD};
        }
    }

    .LocationsBackground {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        height: 130vw;

        mask-image: linear-gradient(to bottom, black 80%, transparent);

        @include breakpoint-tablet-up {
            //height: 56.25vw;
            height: 100%;
            mask-image: linear-gradient(to bottom, black 50%, transparent);
        }

        &-inner {
            @include FULL_COVER;
            mask: url(#{$IMAGE_DIR}mask-locations-edge.svg) center / cover no-repeat;
        }
        &-slide {
            @include FULL_COVER;
            opacity: 0;
            transition: opacity 1s;
            background: center / cover no-repeat;

            &[data-index="0"] {
                background-image: url(#{$IMAGE_DIR}bg-hub-streets.jpg);
            }
            &[data-index="1"] {
                background-image: url(#{$IMAGE_DIR}bg-hub-mall.jpg);
            }
            &[data-index="2"] {
                background-image: url(#{$IMAGE_DIR}bg-hub-underground.jpg);
            }

            &.is-active {
                opacity: 1;
                z-index: 1;
            }
        }
    }
    .LocationsSection {
        background: linear-gradient(to bottom, transparent 20vw, #1E1F19 50vw);

        @include breakpoint-tablet-up {
            aspect-ratio: 16/9;
        }
    }
    .LocationsGrid {
        position: relative;
        z-index: 2;

        @include breakpoint-mobile-only {
            padding-top: 100vw;
        }
        @include breakpoint-tablet-up {
            padding-top: 40vw;
        }

        &-inner {
            color: $COLOR_INVERTED_TEXT;
            padding-bottom: $SECTION_VERTICAL_PADDING_MOBILE;

            @include breakpoint-tablet-up {
                padding-bottom: $SECTION_VERTICAL_PADDING_TABLET;
            }
        }
        &-title {
            @include CONTENT_CONTRAST_FILTER;
            @include TYPOGRAPHY_H2_ALTERNATE;
        }
        &-description {
            @include CONTENT_CONTRAST_FILTER;
            max-width: 800px;
            margin-right: auto;
            line-height: $LINE_HEIGHT_PARAGRAPH;
        }
        &-grid {
            display: grid;
            grid-template-columns: minmax(0, 1fr);
            gap: $GRID_GAP;

            @include breakpoint-mobile-only {
                gap: $SPACE_MEDIUM;
            }
            @include breakpoint-tablet-up {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        }
    }
    .LocationCard {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        background-color: #111111;
        cursor: pointer;

        --color-card-label: #{$COLOR_INVERTED_TEXT};

        &[data-index="0"] {
            --location-card-thumbnail: url(#{$IMAGE_DIR}thumb-hub-streets.png);
        }
        &[data-index="1"] {
            --location-card-thumbnail: url(#{$IMAGE_DIR}thumb-hub-mall.jpg);
        }
        &[data-index="2"] {
            --location-card-thumbnail: url(#{$IMAGE_DIR}thumb-hub-underground.jpg);
        }

        &:after {
            content: "";
            @include FULL_COVER;
            border: solid 1px transparent;
            pointer-events: none;
            transition: border $TRANSITION_FAST;
        }

        &-image {
            aspect-ratio: 16/9;
            background: center / cover no-repeat var(--location-card-thumbnail);
        }
        &-text {
            padding: $CARD_CONTENT_PADDING;
            transition: color $TRANSITION_FAST;

            @include breakpoint-mobile-only {
                font-size: $FONT_SIZE_SUB;
                padding: $SPACE_SMALL $SPACE_SMALL 12px;
            }
        }
        &-label {
            color: var(--color-card-label, #{$COLOR_INVERTED_TEXT});
            font-weight: 700;
        }
        &-description {
            margin-top: $SPACE_SMALL;
            line-height: $LINE_HEIGHT_PARAGRAPH;
        }

        &.is-active,
        &:hover {
            --color-card-label: #FF8F75;

            &:after {
                border-color: rgba($COLOR_WHITE, 0.5);
            }
        }
    }

    .EnemiesSection {
        background: linear-gradient(to bottom, #1E1F19 20vw, $COLOR_1999_HUB_BACKGROUND_BOTTOM 50vw);
    }
    .TechnocyteBackground {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 49.6875vw;

        background: center / cover no-repeat url(#{$IMAGE_DIR}bg-hub-onlyne.jpg);
        mask-image: linear-gradient(to bottom, transparent, black 10%, black 90%, transparent);
    }
    .TechnocyteGrid {
        position: relative;

        @include breakpoint-tablet-down {
            padding-top: 40vw;
        }
        @include breakpoint-desktop-up {
            height: 49.6875vw;
            padding-top: 0;
        }

        &-inner {
            @include breakpoint-tablet-down {
                text-align: center;
            }
            @include breakpoint-desktop-up {
                position: absolute;
                left: 0;
                bottom: $SECTION_VERTICAL_PADDING_TABLET;
                width: 50%;
            }
        }
        &-description {
            @include CONTENT_CONTRAST_FILTER;

            strong {
                font-weight: bold;
            }
        }
        &-disclaimer {
            @include breakpoint-desktop-up {
                margin-bottom: -40px;
            }
        }

        &-album {
            position: relative;

            background: linear-gradient(90deg, #985D99 0%, #F87585 100%);
            border: solid $SPACE_SMALLER $COLOR_MEDIUM_GRAY;
            filter: drop-shadow(0 4px 4px rgba($COLOR_BLACK, 0.25));

            @include breakpoint-tablet-down {
                margin-top: 2em;
                margin-bottom: 4em;
            }

            @include breakpoint-desktop-up {
                position: absolute;
                bottom: $SPACE_LARGE;
                right: 0;
                width: 40%;
            }
        }
        &-albumTitle {
            padding: $SPACE_SMALL 50% $SPACE_SMALL $SPACE_SMALL;
            white-space: nowrap;
            font-weight: bold;
            text-transform: uppercase;

            @include breakpoint-desktop-up {
                padding: $SPACE_SMALL 40% $SPACE_SMALL $SPACE_SMALL;
            }
        }
        &-albumLink {
            --color-link: #{$COLOR_WHITE};
            --color-link-hover: #{$COLOR_WHITE};
        }
        &-albumDescription {
            border-top: solid $SPACE_SMALLER $COLOR_MEDIUM_GRAY;
            padding: $SPACE_MEDIUM 50% $SPACE_MEDIUM $SPACE_SMALL;

            @include breakpoint-tablet-only {
                padding: 24px 30% 24px $SPACE_SMALL;
            }
            @include breakpoint-desktop-up {
                padding: 24px 40% 24px $SPACE_SMALL;
            }
        }
        &-albumCover {
            position: absolute;
            display: block;
            top: 50%;
            right: 25%;
            width: 40%;
            aspect-ratio: 1;
            transform: translate(50%, -50%) rotate(10deg);
            filter: drop-shadow(0 4px 4px rgba($COLOR_BLACK, 0.25));

            @include breakpoint-tablet-only {
                width: 20%;
                right: 15%;
            }
            @include breakpoint-desktop-up {
                width: 35%;
                right: 20%;
            }
        }
        &-tour {
            position: absolute;
            top: 0;
            right: 0;
            width: 60%;
            aspect-ratio: 989/543;
            @include breakpoint-tablet-up {
                width: 40%;
            }
        }
    }

    .ScaldraGrid {
        position: relative;
        background-color: rgba($COLOR_BLACK, 0.8);
        aspect-ratio: 16/9;

        margin-top: $SPACE_LARGE;

        &:after {
            content: "";
            @include FULL_COVER;
            border: 2px solid;
            border-image: linear-gradient(to right, rgba($COLOR_SCALDRA_BORDER, 55%) 0, $COLOR_SCALDRA_BORDER 50%, rgba($COLOR_SCALDRA_BORDER, 55%) 100%) 1;
            pointer-events: none;
            z-index: 2;
        }

        &-backgrounds {
            @include FULL_COVER;

            @include breakpoint-tablet-up {
                &:after {
                    content: "";
                    @include FULL_COVER;
                    background: linear-gradient(to right, rgba($COLOR_BLACK, 0.5) 40%, transparent 60%);
                    z-index: 2;
                    pointer-events: none;
                }
            }
        }
        &-background {
            @include FULL_COVER;
            opacity: 0;
            transition: opacity 1s;
            background: center / cover no-repeat;

            &[data-index="0"] {
                background-image: url(#{$IMAGE_DIR}bg-hub-scaldra.jpg);
            }
            &[data-index="1"] {
                background-image: url(#{$IMAGE_DIR}bg-hub-major.jpg);
                @include breakpoint-mobile-only {
                    background-position: right;
                }
            }
            &[data-index="2"] {
                background-image: url(#{$IMAGE_DIR}../placeholder-16x9.png);
            }
            &[data-index="3"] {
                background-image: url(#{$IMAGE_DIR}bg-hub-tank.jpg);
                @include breakpoint-mobile-only {
                    background-position: right;
                }
            }

            &.is-active {
                opacity: 1;
                z-index: 1;
            }
        }
        &-content {
            position: relative;
            z-index: 2;

            @include breakpoint-desktop-up {
                height: 100%;
                margin-left: auto;
                margin-right: auto;
                width: $GRID_DESKTOP_TEN_COLUMNS;
            }
        }
        &-inner {
            position: relative;

            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto 1fr;

            padding-top: 0;
            padding-bottom: $SECTION_VERTICAL_PADDING_MOBILE;
            overflow: hidden;

            @include breakpoint-tablet-up {
                height: 100%;

                grid-template-rows: auto 1fr auto;
                padding-top: $SECTION_VERTICAL_PADDING_TABLET;
                padding-bottom: $SECTION_VERTICAL_PADDING_TABLET;
                padding-right: 35%;
            }
            @include breakpoint-wide-up {
                padding-right: 50%;
            }
        }
        &-pages {
            position: relative;
            @include breakpoint-tablet-down {
                order: 3;
            }
        }
        &-page {
            position: relative;
            display: none;

            &.is-active {
                display: block;
            }
        }
        &-pretitle,
        &-title,
        &-description {
            @include CONTENT_CONTRAST_FILTER;

            @include breakpoint-tablet-down {
                padding-left: $SPACE_LARGE;
                padding-right: $SPACE_LARGE;
            }
        }
        &-pretitle {
            margin-bottom: 1rem;
            @include TYPOGRAPHY_H3_ALTERNATE;
            @include breakpoint-tablet-down {
                order: 2;
            }
            @include breakpoint-mobile-only {
                margin-bottom: 20rem;
            }
        }
        &-pretitleInner {
            position: relative;
            display: inline-block;

            &:after {
                content: "";
                position: absolute;
                top: 30%;
                left: 0;
                width: 280%;
                height: 100%;
                background: left center / 100% 100% no-repeat url(#{$IMAGE_DIR}scaldra-line.svg);
            }
        }
        &-title {
            @include TYPOGRAPHY_H1_ALTERNATE;
        }
        &-description {
            margin: $SPACE_MEDIUM 0;
            font-size: $FONT_SIZE_PARAGRAPH;
            line-height: $LINE_HEIGHT_PARAGRAPH;
        }

        &-tiles {

            @include breakpoint-mobile-only {
                order: 1;
                margin-bottom: 1em;

                display: grid;
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
            @include breakpoint-tablet-only {
                order: 1;

                display: flex;
                flex-flow: row nowrap;
                gap: $SPACE_SMALL;
                padding-left: $SPACE_LARGE;
                padding-right: $SPACE_LARGE;

                margin-bottom: 1em;
            }
            @include breakpoint-desktop-up {
                display: flex;
                flex-flow: row nowrap;
                gap: $SPACE_MEDIUM;
                margin-top: 1em;
            }
        }
        &-tile {
            position: relative;
            aspect-ratio: 1;
            background: center / cover no-repeat;
            cursor: pointer;
            filter: saturate(0);
            transition: filter $TRANSITION_FAST;

            @include breakpoint-tablet-up {
                width: 100px;
            }

            @include breakpoint-desktop-up {
                width: 120px;
            }
            @include breakpoint-wide-up {
                width: 150px;
            }

            &:after {
                content: "";
                @include FULL_COVER;
                border: solid 2px rgba($COLOR_SCALDRA_BORDER, 0.5);
                transition: border $TRANSITION_FAST;
                pointer-events: none;
            }

            &[data-index="0"] {
                background-image: url(#{$IMAGE_DIR}thumb-hub-scaldra.jpg);
            }
            &[data-index="1"] {
                background-image: url(#{$IMAGE_DIR}thumb-hub-major.jpg);
            }
            &[data-index="2"] {
                background-image: url(#{$IMAGE_DIR}../placeholder-1x1.png);
            }
            &[data-index="3"] {
                background-image: url(#{$IMAGE_DIR}thumb-hub-tank.jpg);
            }

            &.is-active,
            &:hover {
                filter: saturate(1);
                &:after {
                    border-color: $COLOR_SCALDRA_BORDER;
                }
            }
        }
    }
    .InfestedGrid {
        position: relative;
        background-color: #362120;
        margin-top: $SPACE_LARGER;
        margin-bottom: $SECTION_VERTICAL_PADDING_MOBILE;

        @include breakpoint-tablet-up {
            margin-bottom: $SECTION_VERTICAL_PADDING_TABLET;

            &:before,
            &:after {
                content: "";
                position: absolute;
                height: $SPACE_SMALLER;
                background-color: #FF0000;
            }
            &:before {
                left: 10%;
                bottom: 100%;
                width: 30%;
            }
            &:after {
                top: 100%;
                right: 10%;
                width: 40%;
            }
        }

        &-background {
            position: absolute;
            top: 0;
            left: 0;

            background: 23% center / cover no-repeat url(#{$IMAGE_DIR}card-hub-infested.jpg);

            @include breakpoint-mobile-only {
                right: 0;
                height: 100vw;
                background-position: 45% center;
                mask-image: linear-gradient(to bottom, black 60%, transparent 90%);
            }
            @include breakpoint-tablet-up {
                bottom: 0;
                width: 80%;
                mask-image: linear-gradient(to right, black 60%, transparent);
            }
        }
        &-text {
            position: relative;

            @include breakpoint-mobile-only {
                padding: 80vw $SPACE_MEDIUM $SPACE_MEDIUM;
            }
            @include breakpoint-tablet-up {
                min-height: 30vw;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                padding: $SPACE_LARGE 10% $SPACE_LARGE 50%;

                @include CONTENT_CONTRAST_FILTER;
            }
        }
        &-title {
            @include TYPOGRAPHY_H3_ALTERNATE;
        }
    }

    .MissionsBackground {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        aspect-ratio: 16/9;
        background: center / cover no-repeat url(#{$IMAGE_DIR}bg-hub-missions.jpg);
        mask-image: linear-gradient(to bottom, black 80%, transparent);
    }
    .MissionsGrid {
        position: relative;
        padding-top: 40vw;
        padding-bottom: $SECTION_VERTICAL_PADDING_MOBILE;

        @include breakpoint-tablet-up {
            padding-bottom: $SECTION_VERTICAL_PADDING_TABLET;
        }

        &-panel {
            position: relative;
            background: rgba($COLOR_BLACK, 0.7);
            border-top: 1px solid rgba($COLOR_WHITE, 0.5);
            max-width: 800px;
            margin: 0 auto;

            @include STANDARD_SECTION_PADDING;
        }

        &-image {
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
        }
        &-title {
            color: $COLOR_WHITE;
            text-align: center;
            margin-bottom: $SPACE_LARGE;

            @include chromaticAberration2;

            @include TYPOGRAPHY_H1_ALTERNATE;
        }
        &-text {
            @include breakpoint-mobile-only {
                padding: 0 $CONTENT_MARGIN_MOBILE;
            }
            @include breakpoint-tablet-only {
                padding: 0 $CONTENT_MARGIN_TABLET;
            }
            @include breakpoint-tablet-up {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }
    }

    .GameplaySection {
    }
    .GameplayBackground {
        @include FULL_COVER;

        &-slide {
            @include FULL_COVER;
            opacity: 0;
            pointer-events: none;
            background: center / cover no-repeat;
            transition: opacity $TRANSITION_FAST;

            &[data-id="atomicycles"] {
                background-image: url(#{$IMAGE_DIR}bg-hub-atomicycles.jpg);
            }
            &[data-id="weapons"] {
                background-image: url(#{$IMAGE_DIR}bg-hub-weapons.jpg);
            }

            &.is-active {
                opacity: 1;
                pointer-events: all;
            }
        }
        @include breakpoint-tablet-down {
            bottom: auto;
            aspect-ratio: 16/9;
            mask-image: linear-gradient(to bottom, black 80%, transparent);
        }
    }
    .GameplayGrid {
        --collapsible-background: #{rgba($COLOR_BLACK, 0.8)};
        --collapsible-text-color: #{$COLOR_WHITE};

        .CollapsibleActivatorItem-title {
            // Overlap sticker
            z-index: 1;
        }

        &-atomicycles {
            --collapsible-margin-background-active: #FC3B3A;
            --collapsible-title-color: #FC3B3A;
        }
        &-weapons {
            --collapsible-margin-background-active: #{$COLOR_SCALDRA_BORDER};
            --collapsible-title-color: #{$COLOR_SCALDRA_BORDER};
        }

        @include breakpoint-tablet-down {
            padding-top: 50vw;
            padding-bottom: $SECTION_VERTICAL_PADDING_TABLET;
        }
        @include breakpoint-mobile-only {
            padding-bottom: $SECTION_VERTICAL_PADDING_MOBILE;
        }
        @include breakpoint-desktop-up {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            height: 56.25vw;
            margin-right: 60%;
        }
    }
    .KeysSticker {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        width: 200px;
        aspect-ratio: 375/284;
        background: center / contain no-repeat url(#{$IMAGE_DIR}sticker-keys.png);

        @include breakpoint-tablet-down {
            display: none;
        }
    }

    $COLOR_MORE_STUFF_BACKGROUND: #1A282A;
    .StuffSection {
        background-color: $COLOR_MORE_STUFF_BACKGROUND;
    }
    .StuffBackground {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 56.25vw;
        max-height: 100%;
        mask-image: linear-gradient(to bottom, black 50%, transparent);
        overflow: hidden;

        @include breakpoint-desktop-up {
            height: 40vw;
        }

        &-image {
            position: absolute;
            top: 0;
            right: 0;
            aspect-ratio: 16/9;
            height: 100%;
            background: center / cover no-repeat url(#{$IMAGE_DIR}bg-hub-aoi.png);
            mask-image: linear-gradient(to left, black 50%, transparent);
        }
    }
    .StuffGrid {
        position: relative;
        @include breakpoint-mobile-only {
            padding-top: 40vw;
            padding-bottom: $SECTION_VERTICAL_PADDING_MOBILE;
        }
        @include breakpoint-tablet-up {
            padding-top: $SECTION_VERTICAL_PADDING_TABLET;
            padding-bottom: $SECTION_VERTICAL_PADDING_TABLET;
        }

        &-title {
            @include TYPOGRAPHY_H1_ALTERNATE;
            @include breakpoint-mobile-only {
                font-size: $FONT_SIZE_H3;
            }
        }
        &-description {
            margin-top: 1em;

            @include breakpoint-tablet-up {
                padding-right: 50%;
            }
        }

        &-panel {
            position: relative;
            background: linear-gradient(to bottom, rgba(#293334, 0.25), #3E5E60);
            border-top: solid 1px #AAAAAA;
            padding: $SECTION_VERTICAL_PADDING_MOBILE $SPACE_MEDIUM;
            margin-top: $SECTION_VERTICAL_PADDING_MOBILE;

            @include breakpoint-tablet-up {
                padding: $SECTION_VERTICAL_PADDING_TABLET $SPACE_LARGE;
                margin-top: $SECTION_VERTICAL_PADDING_TABLET;
            }
            @include breakpoint-desktop-up {
                padding: $SECTION_VERTICAL_PADDING_TABLET $GRID_DESKTOP_ONE_COLUMN;
                margin-top: $SECTION_VERTICAL_PADDING_TABLET;
            }
        }

        &-inner {
            position: relative;
        }

        &-tabs {
            --color-tab-background: transparent;
            --color-tab-background-hover: #{$COLOR_1999_TITLE};
            --color-tab-background-active: #{$COLOR_1999_TITLE};
            --color-tab-border: #{$COLOR_INVERTED_TEXT};
            --color-tab-border-hover: #{$COLOR_1999_TITLE};
            --color-tab-border-active: #{$COLOR_1999_TITLE};
            --color-tab-text: #{$COLOR_INVERTED_TEXT};
            --color-tab-text-hover: #{$COLOR_TEXT};
            --color-tab-text-active: #{$COLOR_TEXT};
        }

        &-sticker {
            position: absolute;
            left: 0;
            bottom: -40px;
            transform: translateX(-25%);

            width: 200px;
            aspect-ratio: 372/293;
            background: center / contain no-repeat url(#{$IMAGE_DIR}sticker-crane.png);
            pointer-events: none;

            @include breakpoint-mobile-only {
                display: none;
            }
            @include breakpoint-desktop-up {
                bottom: -60px;
                width: 300px;
            }
        }
    }
    .StuffTabContent {
        .SectionLayout-image {
            border: solid 1px rgba($COLOR_1999_TITLE, 0.5);
        }
        &-title {
            color: $COLOR_1999_TITLE;
            @include TYPOGRAPHY_H1;
        }
        &-description {
            line-height: $LINE_HEIGHT_PARAGRAPH;
        }
    }

    .CosmeticsSection {
        background-color: $COLOR_MORE_STUFF_BACKGROUND;
    }
    .CosmeticsBackground {

    }
    .CosmeticsGrid {
        position: relative;
        margin-bottom: $SECTION_VERTICAL_PADDING_MOBILE;
        @include breakpoint-tablet-up {
            margin-bottom: $SECTION_VERTICAL_PADDING_TABLET;
        }

        &-title {
            text-align: center;
            margin-bottom: $SPACE_LARGE;
            @include TYPOGRAPHY_H2_ALTERNATE;
        }
        &-tabs {
            --color-tab-background: transparent;
            --color-tab-background-hover: #{$COLOR_1999_TITLE};
            --color-tab-background-active: #{$COLOR_1999_TITLE};
            --color-tab-border: #{$COLOR_INVERTED_TEXT};
            --color-tab-border-hover: #{$COLOR_1999_TITLE};
            --color-tab-border-active: #{$COLOR_1999_TITLE};
            --color-tab-text: #{$COLOR_INVERTED_TEXT};
            --color-tab-text-hover: #{$COLOR_TEXT};
            --color-tab-text-active: #{$COLOR_TEXT};
        }
        &-layout {
            .SectionLayout-image {
                border: solid 1px rgba($COLOR_1999_TITLE, 0.5);
            }
        }
        &-subtitle {
            color: $COLOR_1999_TITLE;
            @include TYPOGRAPHY_H3;
        }
    }

    .PacksSection {
        color: $COLOR_TEXT;
        --color-text: #{$COLOR_TEXT};
        --color-title: #{$COLOR_TITLE};
    }
    .PacksBackground {
        @include FULL_COVER;
        background: linear-gradient(to bottom, #A6E3D8 10%, #E04E94 90%);
        &-divider {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            aspect-ratio: 1920/146;

            background-color: $COLOR_MORE_STUFF_BACKGROUND;
            mask: url(#{$IMAGE_DIR}mask-locations-wave.svg) center / 100% 100% no-repeat;
        }
        &-overlay {
            @include FULL_COVER;
            background: top center / cover no-repeat url(#{$IMAGE_DIR}bg-merch-mobile.svg);

            @include breakpoint-desktop-up {
                background-image: url(#{$IMAGE_DIR}bg-merch-desktop.svg);
            }
        }
    }
    .PacksGrid {
        padding-top: calc(7.6vw + #{$SECTION_VERTICAL_PADDING_MOBILE});
        padding-bottom: $SECTION_VERTICAL_PADDING_MOBILE;

        @include breakpoint-tablet-up {
            padding-top: calc(7.6vw + #{$SECTION_VERTICAL_PADDING_TABLET});
            padding-bottom: $SECTION_VERTICAL_PADDING_TABLET;
        }

        &-title {
            text-align: center;
            color: $COLOR_TITLE;
            @include TYPOGRAPHY_H2_ALTERNATE;
        }

        &-skinsWrapper {
            padding-top: $SECTION_VERTICAL_PADDING_MOBILE;
            padding-bottom: $SECTION_VERTICAL_PADDING_MOBILE;

            @include breakpoint-tablet-up {
                padding-top: $SECTION_VERTICAL_PADDING_TABLET;
                padding-bottom: $SECTION_VERTICAL_PADDING_TABLET;
            }
        }
        &-skinsTitleWrapper {
            position: relative;
            display: inline-block;

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $COLOR_BLACK;
                mask: url(#{$IMAGE_DIR}mask-skins-title.svg) center / 100% 100% no-repeat;
                z-index: 1;
            }
            &:after {
                top: 10px;
                left: 2px;
                background-color: #F45D94;
                z-index: 0;
            }
        }
        &-skinsTitle {
            position: relative;
            color: $COLOR_INVERTED_TITLE;
            padding: $SPACE_LARGE $SPACE_LARGER $SPACE_LARGE $SPACE_LARGE;
            z-index: 2;
            @include TYPOGRAPHY_H3_ALTERNATE;
        }

        &-panel {
            position: relative;
            background: linear-gradient(to bottom, rgba(#D9D9D9, 0.25), rgba($COLOR_WHITE, 0) 100%);
            border-top: solid 1px rgba($COLOR_WHITE, 0.5);
            padding-top: $SECTION_VERTICAL_PADDING_MOBILE;
            padding-bottom: $SECTION_VERTICAL_PADDING_MOBILE;

            @include breakpoint-tablet-up {
                padding-top: $SECTION_VERTICAL_PADDING_TABLET;
                padding-bottom: $SECTION_VERTICAL_PADDING_TABLET;
            }
        }

        &-cardText {
            height: 100%;
            display: grid;
            grid-template-rows: auto 1fr auto;
        }
        &-marketCard {
            --card-background: #{rgba(#04080D, 0.5)};
            --card-border-color: transparent;
            color: $COLOR_INVERTED_TEXT;

            .Card-image {
                background-position: top center;
            }
        }
        &-marketTitle {
            color: $COLOR_1999_TITLE;
            @include TYPOGRAPHY_H4;
        }
        &-marketButton {
            --color-button-text: #{$COLOR_BLACK};
            --color-button-text-hover: #{$COLOR_BLACK};
            --color-button-background: #{$COLOR_1999_TITLE};
            --color-button-background-hover: #{$COLOR_WHITE};
            --color-button-border: transparent;
            --color-button-border-hover: transparent;
        }
        &-merchCard {
            --card-background: #BDBDBD;
            --card-border-color: transparent;
            color: $COLOR_TEXT;
            border-right: solid 2px #5C5C5C;
            border-bottom: solid 2px #5C5C5C;
            box-shadow: 0 4px 4px rgba($COLOR_BLACK, 0.25);
        }
        &-merchTitle {
            color: $COLOR_TEXT;
            @include TYPOGRAPHY_H4;
        }
        &-merchButton {
            --filter-button-icon: none;
        }

        &-onlyne {
            position: relative;
            margin-top: $SECTION_VERTICAL_PADDING_MOBILE;

            @include breakpoint-tablet-up {
                margin-top: $SECTION_VERTICAL_PADDING_TABLET;
            }
        }
    }
    .PacksMerchMedia {
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: auto minmax(0, 1fr);
        gap: $SPACE_SMALLER;
        padding: $SPACE_SMALLER $SPACE_SMALLER 0 $SPACE_SMALLER;
        font-family: VT323, "Arial", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2;

        &-bar {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            padding: $SPACE_SMALLER;
            background-color: $COLOR_BLACK;
            color: $COLOR_INVERTED_TEXT;
        }
        &-closer {
            width: 24px;
            height: 24px;
            border-top: solid 2px $COLOR_WHITE;
            border-left: solid 2px $COLOR_WHITE;
            border-right: solid 2px #5C5C5C;
            border-bottom: solid 2px #5C5C5C;
            background: #BDBDBD center / 70% no-repeat url(#{$IMAGE_DIR}close.svg);
        }
        &-imageWrapper {
            position: relative;
        }
        &-image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            vertical-align: bottom;
        }
    }
    .PacksOnlynePanel {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        gap: $SPACE_SMALLER;
        padding: $SPACE_SMALLER;
        background-color: #BDBDBD;
        color: $COLOR_TEXT;
        border-right: solid 2px #5C5C5C;
        border-bottom: solid 2px #5C5C5C;
        box-shadow: 0 4px 4px rgba($COLOR_BLACK, 0.25);

        @include breakpoint-desktop-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &-bar {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            padding: $SPACE_SMALLER;
            background-color: $COLOR_BLACK;
            color: $COLOR_INVERTED_TEXT;
            font-family: VT323, "Arial", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.2;

            @include breakpoint-desktop-up {
                grid-column: span 2;
            }
        }
        &-closer {
            width: 24px;
            height: 24px;
            border-top: solid 2px $COLOR_WHITE;
            border-left: solid 2px $COLOR_WHITE;
            border-right: solid 2px #5C5C5C;
            border-bottom: solid 2px #5C5C5C;
            background: #BDBDBD center / 70% no-repeat url(#{$IMAGE_DIR}close.svg);
        }
        &-media {
            position: relative;

            @include breakpoint-tablet-down {
                aspect-ratio: 3/2;
            }
            @include breakpoint-desktop-up {
                order: 3;
            }
        }
        &-album {
            position: absolute;
            top: 40%;
            left: 50%;
            width: 60%;
            aspect-ratio: 1;
            transform: translate(-50%, -50%) rotate(6deg);
            background: center / cover no-repeat url(/images/promo/comics/music/soundtrack-1999.jpg);
            box-shadow: 8px 8px 0 0 rgba($COLOR_BLACK, 0.25);
            @include breakpoint-desktop-up {
                top: 25%;
            }
        }
        &-bolt,
        &-star,
        &-note {
            position: absolute;
            pointer-events: none;
            background: center / contain no-repeat;
        }
        &-bolt {
            top: 3%;
            left: 2%;
            aspect-ratio: 155/229;
            width: 12%;
            background-image: url(#{$IMAGE_DIR}sticker-bolt.png);
            @include breakpoint-desktop-up {
                left: -10%;
                top: -10%;
            }
        }
        &-star {
            top: 20%;
            right: 8%;
            aspect-ratio: 210/209;
            width: 13%;
            background-image: url(#{$IMAGE_DIR}sticker-sparkle.png);
            @include breakpoint-desktop-up {
                top: 5%;
            }
        }
        &-note {
            bottom: 1%;
            left: 10%;
            aspect-ratio: 236/275;
            width: 14%;
            background-image: url(#{$IMAGE_DIR}sticker-note.png);
            @include breakpoint-desktop-up {
                bottom: 16%;
            }
        }
        &-body {
            position: relative;
            padding: $SPACE_MEDIUM;

            @include breakpoint-tablet-up {
                padding: $SPACE_LARGE $SPACE_LARGER;
            }
            @include breakpoint-desktop-up {
                order: 2;
            }
        }
        &-gradientWrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 90%;
            border-right: solid 2px $COLOR_WHITE;
            border-bottom: solid 2px $COLOR_WHITE;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 70%;
                border-top: solid 2px $COLOR_WHITE;
            }
        }
        &-gradient {
            position: relative;
            height: 100%;
            width: 100%;
            background: linear-gradient(269.26deg, #A5E3D8 -9.31%, #9499C0 11.58%, #8454A9 34.31%, rgba(138, 86, 167, 0.944475) 40.66%, rgba(145, 88, 165, 0.883692) 47.62%, rgba(158, 92, 161, 0.770284) 55.64%, rgba(172, 96, 156, 0.653416) 63.4%, rgba(206, 105, 146, 0.359061) 76.48%, rgba(248, 117, 133, 0) 82%);
            border-right: solid 2px #5C5C5C;
            border-bottom: solid 2px #5C5C5C;
        }
        &-grid {
            @include FULL_COVER;
            background: left top / auto 90% no-repeat url(#{$IMAGE_DIR}hub-album-grid.png);
        }
        &-titleWrapper {
            position: relative;
            border: solid 2px $COLOR_WHITE;
            border-top-color: #919191;
            border-left-color: #919191;
            width: 90%;
            margin-right: auto;
        }
        &-title {
            background-color: $COLOR_WHITE;
            border: solid 2px #E5E5E5;
            border-top-color: $COLOR_BLACK;
            border-left-color: $COLOR_BLACK;
            color: $COLOR_BLACK;
            padding: 4px 8px;
            font-family: VT323, "Arial", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.2;
            text-transform: uppercase;
            @include breakpoint-tablet-up {
                padding: 8px 16px;
                font-size: 32px;
            }
        }
    }

    .FeaturesGrid {

        &-title {
            text-align: center;
            margin-bottom: $SPACE_LARGE;
        }

        &-gallery {
            display: grid;
            grid-template-columns: 300px;
            justify-content: center;
            gap: $SPACE_LARGE;

            a {
                text-decoration: none;
            }

            @include breakpoint-tablet-up {
                grid-template-columns: repeat(2, 300px);
            }
            @include breakpoint-desktop-up {
                grid-template-columns: repeat(3, 350px);
            }
            @include breakpoint-wide-up {
                grid-template-columns: repeat(3, 400px);
            }
        }
    }
    .FeaturesGrid {
        margin: 160px auto 64px;

        @include breakpoint-desktop-up {
            margin: 240px auto 80px;
        }
    }
    .FeaturesBorder {
        border: solid 1px $COLOR_1999_BORDER_MEDIA;
        transition: border $TRANSITION_FAST;

        &:hover {
            border-color: $COLOR_1999_BORDER_MEDIA_HOVER;
        }
    }
    .ZevilaSticker {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-10%, 10%);

        img {
            width: 180px;
            aspect-ratio: 500/400;
            vertical-align: bottom;

            @include breakpoint-tablet-up {
                width: 220px;
            }
            @include breakpoint-desktop-up {
                width: 350px;
            }
        }
    }

    .FeaturesOverlapSpacer {
        height: 115px;

        @include breakpoint-desktop-up {
            height: 164px;
        }
    }

    .FeaturesSection {
        margin-top: -115px;
        @include breakpoint-desktop-up {
            margin-top: -164px;
        }
    }
    .FeaturesBackground {
        @include FULL_COVER;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        pointer-events: none;

        &-header {
            background: right bottom / auto 100% no-repeat url(#{$IMAGE_DIR}home-features-divider.svg);
            height: 115px;

            @include breakpoint-desktop-up {
                height: 164px;
            }
        }
        &-body {
            background: linear-gradient(to bottom, #380E13 0%, $COLOR_1999_HUB_BACKGROUND_TOP 100%);
        }
    }

    .FeaturesCard {
        position: relative;
        border: solid 1px $COLOR_1999_BORDER_MEDIA;
        transition: border $TRANSITION_FAST;
        padding: $SPACE_SMALLER;

        &-imageWrapper {
            padding: $SPACE_MEDIUM;
            background-color: #D9D9D9;
            text-align: center;
        }
        &-image {
            display: inline-block;
            width: 80%;
            aspect-ratio: 576/368;
            margin: 0 auto;
            vertical-align: bottom;
        }
        &-caption {
            padding: $SPACE_SMALL $SPACE_SMALL $SPACE_SMALLER;
            text-align: center;
            font-size: $FONT_SIZE_SUB;
            text-decoration: none;
            color: $COLOR_INVERTED_TEXT;
        }

        &:hover {
            border-color: $COLOR_1999_BORDER_MEDIA_HOVER;
        }

    }
    .EntratiPhone {
        position: absolute;
        left: calc(100% + 4px);
        top: 0;
        width: 25px;
        aspect-ratio: 241/2001;
        background: center / contain no-repeat url(#{$IMAGE_DIR}feature-phone-down.png);

        @include breakpoint-desktop-up {
            width: 30px;
        }

        &.is-up {
            background-image: url(#{$IMAGE_DIR}feature-phone-up.png);
            pointer-events: none;
        }
    }
    .PizzaSticker {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(25%);

        img {
            width: 200px;
            aspect-ratio: 499/318;
            vertical-align: bottom;

            @include breakpoint-tablet-up {
                width: 250px;
            }
            @include breakpoint-desktop-up {
                width: 400px;
            }
        }
    }

    .ScreenFilter {
        position: fixed;
        top: var(--page-header-height);
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        pointer-events: none;
        z-index: 9; // Just below footer

        &-corners {
            @include FULL_COVER;
            background: center / cover no-repeat radial-gradient(
                    closest-corner,
                    transparent 85%,
                    rgba(black, 0.5) 100%
            );
        }

        &-lines {
            @include FULL_COVER;
            background: center / 100% 4px repeat-y linear-gradient(
                    to bottom,
                    rgba(white, 0.1) 2px,
                    transparent 2px,
                    transparent 4px,
            );
            mask-image: linear-gradient(
                    to bottom,
                    black 0,
                    transparent 15%,
                    transparent 90%,
                    rgba(black, 0.7) 100%
            );
        }

        &-burn {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-97%, -50%);
            filter: opacity(0.1) blur(4px);
            width: 200vh;
            border-radius: 50%;
            aspect-ratio: 1;
            background-color: $COLOR_BACKGROUND;
            box-shadow: 0 0 2vw #f00, inset 0 0 2vw #f00;
        }
    }

    .Lightbox {
        &-slideLink {
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                right: 100%;
                background: linear-gradient(50deg, transparent 32px, white 48px, white 64px, transparent 80px);
                opacity: 0.3;
                animation-name: --lightbox-button-shimmer;
                animation-duration: 3s;
                animation-fill-mode: both;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }

        }
    }
}

body {
    &.lang-ru {
        .HomeMastheadTile {
            &-title {
                @include breakpoint-mobile-only {
                    font-size: $FONT_SIZE_SUB;
                }
                @include breakpoint-desktop-only {
                    font-size: $FONT_SIZE_SUB;
                }
            }
        }
    }
}

// Webring styling
.WebRingSection {
    background-color: #130708;
    padding-top: $SPACE_LARGER;
    padding-bottom: $SPACE_LARGER;

    @include breakpoint-desktop-up {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}
.WebRingGrid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: $SPACE_SMALLER;
    padding: $SPACE_SMALL;
    border: solid 2px #d9d9d9;
    color: $COLOR_TEXT;

    width: 90%;
    max-width: 600px;
    margin: 0 auto;

    font-family: "Times New Roman", serif;

    &-content {
        display: grid;
        grid-template-columns: 1fr;
        gap: $SPACE_MEDIUM;

        text-align: center;
        background-color: #D9D9D9;
        padding: $SPACE_LARGE 0;

        @include breakpoint-tablet-up {
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: $SPACE_LARGE;
            padding: $SPACE_LARGE;
        }
    }
    &-icon {
        position: relative;
        aspect-ratio: 1543/564;
        width: 250px;
        margin: 0 auto;

        @include breakpoint-tablet-up {
            width: 145px;
        }

        img {
            width: 100%;
            vertical-align: bottom;
        }
    }
    &-title {
        padding: 0 $SPACE_MEDIUM;
        strong {
            font-weight: bold;
        }
    }

    &-links {
        display: flex;
        flex-flow: row nowrap;
        gap: $SPACE_MEDIUM;
        justify-content: center;
        background-color: #D9D9D9;
        padding: $SPACE_SMALL 0 $SPACE_MEDIUM;
    }

    &-link {
        color: blue;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;

        &:visited {
            color: purple;
        }
        &:hover,
        &:active {
            text-decoration: none;
            color: red;
        }
    }
}

@import "includes/_1999-onlyne";
@import "includes/_1999-atomicycle";
@import "includes/_1999-protoframes";
@import "includes/_1999-comic";
@import "includes/_1999-bigbytespizza";
@import "includes/_1999-phone";
@import "includes/_1999-dangerous-sighting";
