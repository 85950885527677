// Dossier webring page
#protoframes-page {
    $TRANSITION_DOSSIER: 500ms;
    $FONT_FAMILY_DOSSIERS: Arial, Helvetica, sans-serif;

    background-color: $COLOR_BACKGROUND;

    .DossierSection {
        position: relative;
        background: center / cover no-repeat url(#{$IMAGE_DIR}bg-dossiers.jpg);

        @include breakpoint-tablet-down {
            background-position: 75% 50%;
        }

        &:after {
            content: "";
            @include FULL_COVER;
            background-image: radial-gradient(65% 100%, transparent 50%, rgba(0,0,0,.8) 80%);
            pointer-events: none;
            @include breakpoint-desktop-up {
                background-image: radial-gradient(50% 100% at 62.5% 50%, transparent 30%, rgba(0, 0, 0, .8) 60%);
            }
        }
    }

    .DossierGrid {
        padding: $SPACE_HUGE 0;

        &-tileWrapper {
            overflow-x: auto;

            @include breakpoint-desktop-up {
                overflow-x: hidden;
            }
        }
        &-tiles {
            display: inline-flex;
            flex-flow: row nowrap;
            padding: 64px 25vw;

            @include breakpoint-tablet-up {
                padding: 80px 25vw;
            }
            @include breakpoint-desktop-up {
                padding-left: 75vw;
                padding-right: 50vw;
            }
        }
        &-controls {
            display: none;
            @include breakpoint-desktop-up {
                display: block;
                margin-top: -64px;
                margin-left: 50vw;
                margin-right: 25vw;
                text-align: center;
            }
        }
        &-prev,
        &-next {
            display: inline-block;
            padding: 8px;
            background-color: rgba(0,0,0,.5);
            border-radius: 50%;
            aspect-ratio: 1;
            cursor: pointer;

            &> .Icon {
                width: 40px;
                height: 40px;
                vertical-align: bottom;
                filter: invert(1);
            }
        }
        &-prev {

        }
        &-next {

        }
    }

    .DossierTile {
        position: relative;
        padding: 0 8px;

        @include breakpoint-tablet-up {
            padding: 0 16px;
        }

        &-wrapper {
            position: relative;
            display: inline-block;
            width: 325px;
            aspect-ratio: 360/450;
            cursor: pointer;

            @include breakpoint-tablet-up {
                width: 380px;
            }
        }
        &-container {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
        }

        &-inner {
            width: 100%;
            height: 100%;
            transition: all $TRANSITION_DOSSIER;
            transform: rotateY(0);
            transform-style: preserve-3d;
        }
        &-front,
        &-back {
            @include FULL_COVER;
            background-color: #666;
            border: solid 4px #020A0C;
            backface-visibility: hidden;
            filter: drop-shadow(0 4px 5px rgba(0,0,0,.8));
        }
        &-back {
            transform: rotateY(180deg);
        }

        &-label {
            position: absolute;
            left: 4px;
            bottom: 4px;
            right: 4px;
            background-color: #020A0C;
            padding: 8px;
            color: $COLOR_INVERTED_TEXT;
            font-family: $FONT_FAMILY_DOSSIERS;
            text-transform: uppercase;
            text-align: center;
        }

        &-data {
            position: absolute;
            top: 8px;
            bottom: 8px;
            right: 8px;
            left: 8px;
            background-color: #272727;
            border: solid 1px #666;
            padding: 8px;
            color: $COLOR_INVERTED_TEXT;
            font-family: $FONT_FAMILY_DOSSIERS;
            text-align: left;
            overflow-y: auto;
        }
        &-dataTitle {
            font-size: 22px;
            text-transform: uppercase;
            width: 60%;
            margin-right: auto;
        }
        &-dataSubtitle {
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 4px;
        }
        &-dataSubtext {
            font-size: 14px;
            span {
                text-decoration: underline;
            }
        }
        &-dataMatch {
            font-size: 14px;
            color: #888;
            text-transform: uppercase;
            margin-bottom: 8px;
        }
        &-dataBlocks {
            font-family: "Courier New", monospace;
            font-size: 8px;
            line-height: 1.8;
            color: #444;
            margin-bottom: $SPACE_SMALL;

            mask-image: url(#{$IMAGE_DIR}mask-static.png);
            mask-size: cover;

            @include breakpoint-tablet-up {
                font-size: 10px;
            }

            strong {
                color: #888;
            }
        }
        &-dataDivider {
            height: 1px;
            background-color: #666;
            margin: 0 -8px;
        }
        &-dataLabel {
            text-transform: uppercase;
            margin-top: 16px;
            margin-bottom: 8px;
            &:before {
                content: "// ";
            }
            &:after {
                content: ":";
            }
        }

        &-photo {
            position: absolute;
            top: -6.5%;
            left: 50%;
            width: 100%;
            border: solid 12px #C6C6C6;
            aspect-ratio: 750/946;
            transform: translate(-50%, 0) scale(0.9);
            object-fit: cover;
            filter: drop-shadow(0 4px 5px rgba(0,0,0,.8));
            transition: all $TRANSITION_DOSSIER;
            pointer-events: none;
        }
        &-clip {
            position: absolute;
            top: 5%;
            right: 20%;
            width: 30%;
            aspect-ratio: 101/105;
            transform: translate(50%, -50%);
            pointer-events: none;
        }

        // Arthur
        &[data-index="0"] {
            .DossierTile {
                &-photo {
                    transform: translate(-50%, 0) scale(0.9) rotate(1deg);
                }
            }
        }
        // Lettie
        &[data-index="1"] {
            .DossierTile {
                &-photo {
                    transform: translate(-50%, -1.5%) scale(0.9) rotate(3deg);
                }
            }
        }
        // Amir
        &[data-index="2"] {
            .DossierTile {
                &-photo {
                    transform: translate(-50%, 1%) scale(0.9) rotate(-1deg);
                }
            }
        }
        // Aoi
        &[data-index="3"] {
            .DossierTile {
                &-photo {
                    transform: translate(-50%, 1.5%) scale(0.9) rotate(-2deg);
                }
            }
        }
        // Eleanor
        &[data-index="4"] {
            .DossierTile {
                &-photo {
                    transform: translate(-50%, 2%) scale(0.9) rotate(-3deg);
                }
            }
        }
        // Quincy
        &[data-index="4"] {
            .DossierTile {
                &-photo {
                    transform: translate(-50%, -1.5%) scale(0.9) rotate(3deg);
                }
            }
        }

        &.is-blurred {
            filter: grayscale(1);
        }
        &.is-flipped {
            .DossierTile {
                &-inner {
                    transform: rotateY(-180deg);
                }
                &-photo {
                    border: solid 6px #C6C6C6;
                    transform: translate(-25%, -24%) scale(0.45) rotate(3deg);
                }
                &-clip {
                    animation-name: --paperclip-flip;
                    animation-duration: $TRANSITION_DOSSIER;
                    animation-iteration-count: 1;
                    animation-fill-mode: both;
                }
            }
        }
        &.is-unflipped {
            .DossierTile {
                &-clip {
                    animation-name: --paperclip-unflip;
                    animation-duration: $TRANSITION_DOSSIER;
                    animation-iteration-count: 1;
                    animation-fill-mode: both;
                }
            }
        }
    }

    g-ene,
    g-ien,
    g-enet,
    g-en {
        display: inline-block;
        aspect-ratio: 1/3;
        height: 22px;
        border: solid 1px rgba($COLOR_WHITE, 0.3);
        margin-right: 2px;
        @include breakpoint-tablet-up {
            height: 28px;
        }
    }
    g-ene {
        background-color: #644;
    }
    g-ien {
        background-color: #664;
    }
    g-enet {
        background-color: #446;
    }
    g-en {
        background-color: #464;
    }
}

body.warframe_1999_protoframes {
    background-color: $COLOR_INVERTED_BACKGROUND;
}
