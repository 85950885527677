// Atomicycle webring page
#atomicycle {
    position: relative;
    background-color: #161F2D;
    color: $COLOR_INVERTED_TEXT;
    --color-title: $COLOR_INVERTED_TEXT;
    --color-text: $COLOR_INVERTED_TEXT;
    font-family: $FONT_FAMILY_SERIF;
    line-height: 1.5;
    overflow: hidden;

    .Section-wrapper {
        overflow: visible;
    }

    strong {
        font-weight: bold;
    }

    .HighlightedText {
        position: relative;

        span:not(.EntratiNote) {
            background-color: #BFFD82;
            color: #000;
        }
    }
    .MastheadSection {
        position: relative;
        overflow: hidden;

        @include breakpoint-tablet-up {
            mask-image: linear-gradient(to top, transparent, black 9%);
        }

        &-background {
            @include FULL_COVER;
            background: #DDD;
            @include breakpoint-desktop-up {
                mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
            }
        }
        &-inner {
            position: relative;
            max-width: 1440px;
            margin: 0 auto;
        }
    }
    .MastheadLayout {
        position: relative;
    }
    .MastheadBreadcrumbs {
        position: absolute;
        top: 24px;
        left: 24px;
        font-size: 24px;
        text-align: left;
        color: $COLOR_TEXT;
        display: flex;
        flex-flow: row nowrap;
        gap: 16px;
        z-index: 4;
    }
    .MastheadLogo {
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;

        @include breakpoint-tablet-up {
            top: 24px;
        }

        img {
            aspect-ratio: 936/382;
            width: 300px;
            @include breakpoint-desktop-up {
                width: 404px;
            }
        }
    }

    $TRANSITION_SLIDESHOW: 2s;
    .MastheadSlideshow {
        position: relative;
        aspect-ratio: 750/834;

        @include breakpoint-tablet-up {
            aspect-ratio: 3500/2028;
        }

        &-slides {
            position: relative;
            width: 100%;
            height: 100%;
        }
        &-slide {
            @include FULL_COVER;
        }
        &-controls {
            position: absolute;
            bottom: 5%;
            left: 0;
            right: 0;
            z-index: 4;

            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            gap: $SPACE_SMALL;

            @include breakpoint-tablet-up {
                bottom: 9%;
            }
        }
        &-control {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: linear-gradient(to bottom, #fff, #ccc);
            transition: background-color $TRANSITION_SLIDESHOW;
            cursor: pointer;

            &[data-index="1"] { --pip-color-one: #7e8a9a; --pip-color-two: #e1dee5; }
            &[data-index="2"] { --pip-color-one: #dd5350; --pip-color-two: #ebebeb; }
            &[data-index="3"] { --pip-color-one: #525252; --pip-color-two: #f8d675; }
            &[data-index="4"] { --pip-color-one: #60d0dc; --pip-color-two: #afda13; }

            &:before {
                content: "";
                position: absolute;
                top: 1px;
                bottom: 1px;
                left: 1px;
                right: 1px;
                border-radius: 50%;
                background: linear-gradient(to bottom, var(--pip-color-one, #FFF) 20%, var(--pip-color-two, #FFF) 80%);
            }
            &:after {
                content: "";
                position: absolute;
                top: 4px;
                bottom: 4px;
                left: 4px;
                right: 4px;
                border: solid 1px #000;
                border-radius: 50%;
                background-color: rgba(0,0,0,.5);
                transition: all $TRANSITION_SLIDESHOW;
            }

            &.is-active {
                &:after {
                    background-color: rgba(255,255,255,.9);
                }
            }
        }
    }
    .MastheadOverlay {
        @include FULL_COVER;
        background: center / cover no-repeat url(#{$IMAGE_DIR}atomicycle-overlay-mobile.png);
        z-index: 6;
        @include breakpoint-tablet-up {
            background-image: url(#{$IMAGE_DIR}atomicycle-overlay.png);
        }
    }
    .MastheadImage {
        opacity: 0;
        pointer-events: none;
        transition: opacity $TRANSITION_SLIDESHOW;
        z-index: 1;

        img {
            width: 100%;
            vertical-align: bottom;
            object-fit: cover;
        }
        &-mobile {
            aspect-ratio: 750/834;
            @include breakpoint-tablet-up {
                display: none;
            }
        }
        &-desktop {
            aspect-ratio: 3500/2028;
            @include breakpoint-mobile-only {
                display: none;
            }
        }

        &.is-active {
            opacity: 1;
            z-index: 3;
            pointer-events: all;
        }
        &.is-matte {
            opacity: 1;
            z-index: 2;
        }
    }

    .PageContent {
        position: relative;
        max-width: 920px;
        margin: 0 auto;
    }
    .PageTitle {
        font-family: $FONT_FAMILY_SERIF;
        font-size: 28px;
        text-align: center;
        text-shadow: 4px 4px 7px rgba(255,255,255,.44);
        @include breakpoint-tablet-up {
            font-size: 54px;
        }
        @include breakpoint-desktop-up {
            font-size: 60px;
        }
    }
    .PageDescription {
        font-family: Arial, sans-serif;
        --font-family-body: Arial, sans-serif;
        margin-top: $SPACE_MEDIUM;

        .HighlightedText {
            @include breakpoint-desktop-up {
                padding-left: $SPACE_LARGE;
                padding-right: 20%;
            }
        }
    }
    .PageNav {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 2px;
        margin: $SPACE_LARGER 0;

        font-family: Arial, Helvetica, sans-serif;
        color: $COLOR_TEXT;
        text-align: center;

        @include breakpoint-tablet-up {
            grid-template-columns: auto 1fr auto;
        }

        &-logo,
        &-end,
        &-button,
        &-blank {
            position: relative;
            background-color: #B1B7BE;
            padding: $SPACE_SMALLER;
        }
        @include breakpoint-mobile-only {
            &-logo {
                grid-column: 1;
                grid-row: 2;
            }
            &-end {
                grid-column: 2;
                grid-row: 2;
            }
        }
        &-logo {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            padding: 0 16px;
            img {
                vertical-align: bottom;
                width: 107px;
            }
        }
        &-buttons {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 2px;
            @include breakpoint-mobile-only {
                grid-column: 1 / span 2;
                grid-row: 1;
            }
        }
        &-button {
            cursor: pointer;

            &:after {
                content: "";
                @include FULL_COVER;
                border: solid 2px rgba(255,255,255,0.5);
                border-bottom-color: rgba(0,0,0,0.3);
                border-right-color: rgba(0,0,0,0.3);
                pointer-events: none;
            }
            &:hover {
                background-color: #CFD7DE;
            }
            &.is-active {
                background-color: #CFD7DE;
            }
        }
        &-end {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            font-family: $FONT_FAMILY_SERIF;
            font-size: 31px;
            font-weight: 700;
            padding: 0 24px;
        }
    }

    .BodyPages {
        position: relative;
    }
    .BodyPage {
        display: none;
        position: relative;

        @include breakpoint-desktop-up {
            padding: 0 $SPACE_LARGE;
        }

        &.is-active {
            display: block;
        }
    }
    .BodyPageGrid {
        display: grid;
        grid-template-columns: 1fr;
        gap: $SPACE_LARGE;

        @include breakpoint-tablet-up {
            grid-template-columns: auto 1fr;
        }

        &-left {
            @include breakpoint-mobile-only {
                display: grid;
                gap: $SPACE_MEDIUM;
                grid-template-columns: auto 1fr;
            }
            @include breakpoint-tablet-up {
                width: 126px;
            }
            @include breakpoint-desktop-up {
                width: 175px;
            }
        }

        &-image {
            display: block;
            width: 126px;
            aspect-ratio: 1;
            background-color: rgba(255,255,255,.5);
            @include breakpoint-desktop-up {
                width: 175px;
            }
        }
        &-caption {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            color: #FF5336;
            margin-top: $SPACE_SMALLER;
            margin-bottom: $SPACE_SMALL;
            @include breakpoint-mobile-only {
                grid-row: 2;
                grid-column: 1;
            }
        }
        &-subtext {
            font-family: Arial, Helvetica, sans-serif;
            line-height: 1.5;
            @include breakpoint-mobile-only {
                grid-row: 1 / span 2;
                grid-column: 2;
            }
        }

        &-title {
            font-size: 24px;
            font-weight: bold;
            text-transform: uppercase;

            @include breakpoint-desktop-up {
                font-size: 32px;
            }
        }
        &-text {
            position: relative;
        }

        p {
            margin: $SPACE_LARGE 0;
            font-family: $FONT_FAMILY_SERIF;
            font-size: 24px;
            line-height: 1.5;
        }
    }

    .DetailGrid {
        position: relative;

        &-header {
            padding: 0 32px 60px;
        }

        &-title {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: $SPACE_MEDIUM;

            @include breakpoint-desktop-up {
                font-size: 36px;
            }
        }
        &-subtitle {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.2;
            margin-bottom: $SPACE_MEDIUM;

            @include breakpoint-desktop-up {
                font-size: 32px;
            }
        }
        &-disclaimer {
            margin-top: $SPACE_MEDIUM;
        }

    }
    .DetailGridSection {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
        padding: $SPACE_LARGE;

        @include breakpoint-tablet-up {
            gap: $SPACE_LARGE;
            padding: $SPACE_LARGER;

            grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
            align-items: center;
            margin-left: -32px;
            margin-right: -32px;
        }

        &-background {
            @include FULL_COVER;
            background: center / cover no-repeat;
            &:after {
                content: "";
                @include FULL_COVER;
                opacity: 0.9;
            }

        }
        &-image {
            position: relative;
            aspect-ratio: 16/9;
            background: center / cover no-repeat;
            border: solid 1px #377383;
            filter: drop-shadow(0 4px 4px rgba($COLOR_BLACK, 0.2));
        }
        &-text {
            position: relative;
        }


        &[data-id="beast"],
        &[data-id="glow"],
        &[data-id="commercial"],
        &[data-id="repair"] {
            @include breakpoint-tablet-up {
                grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
            }
            .DetailGridSection {
                &-image {
                    @include breakpoint-tablet-up {
                        order: 2;
                    }
                }
                &-text {
                    @include breakpoint-tablet-up {
                        order: 1;
                    }
                }
            }
        }
        &[data-id="beast"] {
            .DetailGridSection {
                &-background {
                    background-image: url(#{$IMAGE_DIR}atomicycle-beast.jpg);
                    &:after {
                        background-color: #420F14;
                    }
                }
                &-image {
                    background-image: url(#{$IMAGE_DIR}atomicycle-beast.jpg);
                }
            }
        }
        &[data-id="artist"] {
            .DetailGridSection {
                &-background {
                    background-image: url(#{$IMAGE_DIR}atomicycle-artist.jpg);
                    &:after {
                        background-color: #311704;
                        opacity: 0.85;
                    }
                }
                &-image {
                    background-image: url(#{$IMAGE_DIR}atomicycle-artist.jpg);
                }
            }
        }
        &[data-id="glow"] {
            .DetailGridSection {
                &-background {
                    background-image: url(#{$IMAGE_DIR}atomicycle-glow.jpg);
                    &:after {
                        background-color: #2A174B;
                    }
                }
                &-image {
                    background-image: url(#{$IMAGE_DIR}atomicycle-glow.jpg);
                }
            }
        }
        &[data-id="commercial"] {
            .DetailGridSection {
                &-background {
                    background: none;
                }
                &-image {
                    background-image: url(#{$IMAGE_DIR}atomicycle-rescue.jpg);
                    border-color: #C3C7CF;
                }
            }
        }
        &[data-id="repair"] {
            .DetailGridSection {
                &-background {
                    background: none;
                }
                &-image {
                    background-image: url(#{$IMAGE_DIR}atomicycle-rust.jpg);
                    border-color: #C3C7CF;
                }
            }
        }
    }
    .DetailAppointments {
        position: relative;
        padding: 0 60px 60px;
        background-color: rgba($COLOR_BLACK, 0.25);

        @include breakpoint-tablet-up {
            margin-left: -32px;
            margin-right: -32px;
        }

        &-image {
            width: 100%;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            aspect-ratio: 16/9;
            background: center / contain no-repeat url(#{$IMAGE_DIR}atomicycle-naked.png);
        }
        &-title {
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            margin-bottom: $SPACE_MEDIUM;

            @include breakpoint-desktop-up {
                font-size: 36px;
            }
        }
        &-grid {
            display: grid;
            grid-template-columns: 1fr;
            align-items: start;
            gap: $SPACE_LARGE;

            margin-top: $SPACE_LARGE;

            @include breakpoint-tablet-up {
                grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
            }
        }

        &-form {
            display: block;
        }
        &-input,
        &-select,
        &-submit {
            font-family: Arial, Helvetica, sans-serif;
        }
        &-input {
            display: block;
            width: 100%;
            background-color: $COLOR_BLACK;
            border: none;
            border-radius: $SPACE_SMALLER;
            color: $COLOR_WHITE;
            padding: $SPACE_SMALLER $SPACE_MEDIUM;
            margin: 0 0 $SPACE_MEDIUM;
        }
        &-select {
            display: block;
            width: 100%;
            background-color: $COLOR_BLACK;
            border: none;
            border-radius: $SPACE_SMALLER;
            padding: $SPACE_SMALLER $SPACE_MEDIUM;
            color: $COLOR_WHITE;
            margin: 0 0 $SPACE_MEDIUM;
        }
        &-submit {
            margin: 0;
            padding: $SPACE_SMALLER $SPACE_MEDIUM;
            border-radius: $SPACE_SMALLER;
            text-transform: uppercase;
            letter-spacing: 0;

            --color-button-background: #{$COLOR_BLACK};
            --color-button-border: #{$COLOR_MEDIUM_GRAY};
            --color-button-text: #{$COLOR_WHITE};
            --color-button-background-hover: #{$COLOR_WHITE};
            --color-button-border-hover: #{$COLOR_WHITE};
            --color-button-text-hover: #{$COLOR_BLACK};

            &.is-disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }

    .PageFooter {
        margin-top: $SPACE_HUGE;
        text-align: center;
    }
    .PageCopyright {
        margin-top: $SPACE_LARGE;
        margin-bottom: $SPACE_LARGER;
        text-align: center;
    }

    .EntratiNote {
        --note-mask: url(#{$IMAGE_DIR}mask-note.svg);
        position: absolute;
        display: block;
        width: 240px;
        z-index: 7;

        font-family: "Courier New", monospace;
        font-size: 14px;
        color: $COLOR_TEXT;
        filter: brightness(0.85) drop-shadow(0 8px 4px rgba(0,0,0,.05));
        transition: filter $TRANSITION_FAST;
        transform-style: preserve-3d;
        perspective: 800px;
        cursor: grab;

        @include breakpoint-tablet-up {
            width: 300px;
            font-size: 18px;
        }

        &-vome,
        &-fass {
            backface-visibility: hidden;
            transition: transform 500ms;
        }
        &-vome {
            position: relative;
            padding: $SPACE_LARGE;
            min-height: 200px;

            display: block;
            background: linear-gradient(180deg, #BFFD82 0%, #D3FFA8 100%);
            transform: rotateY(0);
            mask-size: 100% 100%;
            mask-image: var(--note-mask);

            &:after {
                content: "";
                @include FULL_COVER;
                background: linear-gradient(to bottom, #BFFD82 32px, transparent 40px);
                mix-blend-mode: multiply;
                opacity: 0.2;
            }
        }
        &-fass {
            @include FULL_COVER;
            background: linear-gradient(180deg, #BFFD82 0%, #D3FFA8 100%);
            mask-size: 100% 100%;
            mask-image: var(--note-mask);
            transform: rotateY(180deg);

            &:before,
            &:after {
                content: "";
                @include FULL_COVER;
            }
            &:before {
                background: var(--note-frag);
                mix-blend-mode: multiply;
                opacity: 0.8;
            }
            &:after {
                background: linear-gradient(to bottom, #BFFD82 32px, transparent 40px);
                mix-blend-mode: multiply;
                opacity: 0.2;
            }
        }
        &-oull {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 20%;
            pointer-events: none;
        }

        &[data-note="masthead1"] {
            --note-frag: center / 120px 120px no-repeat url(#{$IMAGE_DIR}r/61db50.png);
            top: 120px;
            left: 220px;
            @include breakpoint-tablet-up {
                top: 75px;
                left: 580px;
            }
            @include breakpoint-desktop-up {
                left: 750px;
            }
        }
        &[data-note="masthead2"] {
            --note-mask: url(#{$IMAGE_DIR}mask-note-2.svg);
            --note-frag: top right / 117.2px 62.8px no-repeat url(#{$IMAGE_DIR}r/298ab8.png);
            top: 250px;
            left: -80px;
            @include breakpoint-tablet-up {
                top: 200px;
                left: -120px;
            }
            @include breakpoint-desktop-up {
                left: -150px;
            }
        }
        &[data-note="masthead3"] {
            --note-mask: url(#{$IMAGE_DIR}mask-note-3.svg);
            --note-frag: bottom right / 136.3px 79.4px no-repeat url(#{$IMAGE_DIR}r/589ec3.png);
            top: 400px;
            left: 200px;
            @include breakpoint-tablet-up {
                top: 330px;
                left: 550px;
            }
            @include breakpoint-desktop-up {
                left: 720px;
            }
        }
        &[data-note="aero1"] {
            --note-frag: top right / 124.8px 50.8px no-repeat url(#{$IMAGE_DIR}r/3798b4.png);
            top: 1500px;
            left: 80px;
            @include breakpoint-tablet-up {
                top: 920px;
                left: 550px;
            }
            @include breakpoint-desktop-up {
                left: 660px;
            }
        }
        &[data-note="ergo1"] {
            --note-frag: top right / 73.7px 51.1px no-repeat url(#{$IMAGE_DIR}r/2bb2ed.png);
            top: 1150px;
            left: 220px;
            @include breakpoint-tablet-up {
                top: 630px;
                left: 490px;
            }
            @include breakpoint-desktop-up {
                left: 560px;
            }
        }
        &[data-note="ergo2"] {
            --note-mask: url(#{$IMAGE_DIR}mask-note-2.svg);
            --note-frag: top left / 72.6px 65.8px no-repeat url(#{$IMAGE_DIR}r/3657b4.png);
            top: 1420px;
            left: -80px;
            @include breakpoint-tablet-up {
                top: 760px;
                left: -30px;
            }
            @include breakpoint-desktop-up {
                left: -50px;
            }
        }
        &[data-note="ergo3"] {
            --note-frag: top right / 87px 88.2px no-repeat url(#{$IMAGE_DIR}r/1b67ed.png);
            --note-mask: url(#{$IMAGE_DIR}mask-note-3.svg);
            top: 1850px;
            left: 200px;
            @include breakpoint-tablet-up {
                top: 1100px;
                left: 400px;
            }
            @include breakpoint-desktop-up {
                left: 490px;
            }
        }
        &[data-note="engine1"] {
            --note-frag: top right / 197.9px 89.3px no-repeat url(#{$IMAGE_DIR}r/fd6021.png);
            top: 1320px;
            left: 125px;
            @include breakpoint-tablet-up {
                top: 590px;
                left: 530px;
            }
            @include breakpoint-desktop-up {
                left: 410px;
            }
        }
        &[data-note="chassis1"] {
            --note-frag: bottom left / 40.8px 100px no-repeat url(#{$IMAGE_DIR}r/2b2eff.png);
            top: 1350px;
            left: -95px;
            @include breakpoint-tablet-up {
                top: 820px;
                left: 40px;
            }
            @include breakpoint-desktop-up {
                left: 120px;
            }
        }
        &[data-note="chassis2"] {
            --note-frag: bottom left/ 103.6px 87.5px no-repeat url(#{$IMAGE_DIR}r/6f2850.png);
            --note-mask: url(#{$IMAGE_DIR}mask-note-2.svg);
            top: 1960px;
            left: 190px;
            @include breakpoint-tablet-up {
                top: 1200px;
                left: 500px;
            }
            @include breakpoint-desktop-up {
                top: 1120px;
                left: 620px;
            }
        }
        &[data-note="electro1"] {
            --note-frag: right / 80.4px 187.7px no-repeat url(#{$IMAGE_DIR}r/92d984.png);
            top: 1180px;
            left: -60px;
            @include breakpoint-tablet-up {
                top: 705px;
                left: 15px;
            }
            @include breakpoint-desktop-up {
                top: 730px;
                left: 65px;
            }
        }
        &[data-note="electro2"] {
            --note-frag: bottom right / 47.1px 87.8px no-repeat url(#{$IMAGE_DIR}r/4878cd.png);
            --note-mask: url(#{$IMAGE_DIR}mask-note-2.svg);
            top: 1580px;
            left: 80px;
            @include breakpoint-tablet-up {
                top: 970px;
                left: 360px;
            }
            @include breakpoint-desktop-up {
                left: 480px;
            }
        }
        &[data-note="detailing1"] {
            --note-frag: top center / 99.8px 47.2px no-repeat url(#{$IMAGE_DIR}r/797090.png);
            --note-mask: url(#{$IMAGE_DIR}mask-note-3.svg);
            top: 930px;
            left: 150px;
            @include breakpoint-tablet-up {
                top: 670px;
                left: 480px;
            }
            @include breakpoint-desktop-up {
                left: 600px;
            }
            .EntratiNote-vome {
                &:before {
                    content: "";
                    position: absolute;
                    right: 8px;
                    bottom: 0;
                    height: 32px;
                    width: 32px;
                    background: center / contain no-repeat url(#{$IMAGE_DIR}r/flip.png);
                    transform: rotate(5deg);
                }
            }
        }

        &.is-flippable,
        &[data-note="detailing1"] {
            .EntratiNote-oull {
                cursor: ew-resize;
                pointer-events: all;
            }
        }
        &.is-flipped {
            .EntratiNote {
                &-vome {
                    transform: rotateY(-180deg);
                }
                &-fass {
                    transform: rotateY(0);
                }
            }
        }
        &.is-inactive {
            display: none;
        }
        &.is-active {
            display: block;
            filter: brightness(1) drop-shadow(0 8px 4px rgba(0,0,0,.1));
            z-index: 8;
        }
    }

    .DraggableReset {
        position: fixed;
        bottom: $CONTENT_GUTTER_MOBILE;
        right: $CONTENT_GUTTER_MOBILE;
        z-index: 9;
        cursor: pointer;

        @include breakpoint-tablet-up {
            bottom: $CONTENT_GUTTER_TABLET;
            right: $CONTENT_GUTTER_TABLET;
        }

        .Icon {
            width: 48px;
            height: 48px;
            filter: invert(1) drop-shadow(0 1px 2px black) opacity(0.4);
        }

        &.is-hidden {
            display: none;
        }
    }
}
