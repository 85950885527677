#metro {
    .SubwayGrid {
        &-phone {
            position: absolute;

            right: calc(50% + 55vh);
            top: 55%;

            @include breakpoint-tablet-down{
                right: calc(50% + 40vw);
                top: 55%;
            }
        }

        &-phoneImage {
            width: 38px;

            @include breakpoint-tablet-only{
                width: 25px;
            }

            @include breakpoint-mobile-only{
                width: 12px;
            }
            
        }
    }

    .Phone-background {
        background-image: url(#{$IMAGE_DIR}home-phone.png);  
        background-size: 70%;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
}   
    .Phone-background:before {
        content: "";
        position: absolute;
        top: -9999px;
        left: -9999px;
        width: 1px;
        height: 1px;
        background-image: url(#{$IMAGE_DIR}contacts-phone.png), 
                        url(#{$IMAGE_DIR}easter-egg-phone.png);
        background-repeat: no-repeat;
    }

    .modal {
        display: none; 
        position: fixed; 
        z-index: 1000; 
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);

        justify-content: center;
        align-items: center;
    }

    .modal-content {
        background-color: white;
        padding: 20px;
        width: 300px;
        text-align: center;
        border-radius: 10px;
        position: relative;
    }

    .button-row, .number-pad, .enterButton, #dynamicText {
    position: relative;
    z-index: 2; 
    background-color: transparent !important; 
    color: transparent !important; 
    border: none;
    outline: none;
    cursor: pointer;
    opacity: 0%;
}

.num-button {
    background-color: transparent;
    border: none;
    width: 60px; 
    height: 60px;
    z-index: 2;
    cursor: pointer;
}
.modal-content {
    background-color: transparent;  
    padding: 0;
    width: 320px; 
    height: 730px; 
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.enter-btn {
    width: 100%;
    display: flex;
    justify-content: center;
}

#enterButton, #nextButton, #backButton{
    background-color: transparent;
    border: none;
    width: 60px;
    height: 40px;
    cursor: pointer;
}
    .button-row {
        display: flex;
        justify-content: space-between;
        margin-top: 350px;
    }

    .number-pad {
        display: grid;
        grid-template-columns: repeat(3, 50px); 
        gap: 10px;
        margin: 10px 0;
        justify-content: center; 
        align-items: center;     
        width:80%;
    }

    .num-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px !important;
        padding: 15px;
        background-color: black;
        color: white;
        font-size: 18px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        height: 30px;
    }

    #phoneNumber {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        font-size: 18px;
        opacity: 0%;
    }
    .enter-btn {
        display: flex;               
        justify-content: center;   
        align-items: center;       
        height: 50px;              
        width: 100%;                
    }

    #enterButton, #nextButton, #backButton{
        display: flex;            
        justify-content: center;    
        align-items: center;         
        background-color: blue;
        color: white;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        font-size: 18px;
        height: 50px;               
    }

    .close-modal {
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 24px;
        cursor: pointer;
    }
}

