// Shared styles between Tennocon and 1999 hubsite

$chromaticR: rgba( 251, 12, 12, 0.8 );
$chromaticB: rgba( 12, 79, 251, 0.7 );
$chromaticG: rgba( 52, 251, 12, 0.8 );

@keyframes chromaticMove {
    0%{
        text-shadow:
            3px 2px 2px $chromaticR,
            0px -1px 3px $chromaticB,
            0px 0px -2px $chromaticG;
    }
    50%{
        text-shadow:
            3px 2px 2px $chromaticR,
            0px -1px 3px $chromaticB,
            -3px 2px 3px $chromaticG;
    }
    100%{
        text-shadow:
            3px 2px 2px $chromaticR,
            0px -1px 3px $chromaticB,
            2px -1px 2px $chromaticG;
    }
}

@mixin chromaticAberration() {
    transition: opacity 333ms ease;
    text-shadow:
        3px 2px 2px $chromaticR,
        0px -1px 3px $chromaticB,
        -3px 0px 2px $chromaticG;
    animation:
        chromaticMove 4500ms ease infinite,
        pulse ease 3000ms infinite;
}

$chromaticR2: #FF0806;
$chromaticB2: #00AEFF;
$chromaticG2: rgba( 52, 251, 12, 0.8 );

@keyframes chromaticMove2 {
    0%{
        text-shadow:
            3px 2px 2px $chromaticR2,
            0px -1px 3px $chromaticB2,
            0px 0px -2px $chromaticG2;
    }
    50%{
        text-shadow:
            3px 2px 2px $chromaticR2,
            0px -1px 3px $chromaticB2,
            -3px 2px 3px $chromaticG2;
    }
    100%{
        text-shadow:
            3px 2px 2px $chromaticR2,
            0px -1px 3px $chromaticB2,
            2px -1px 2px $chromaticG2;
    }
}

@mixin chromaticAberration2() {
    transition: opacity 333ms ease;
    text-shadow:
        3px 2px 2px $chromaticR2,
        0px -1px 3px $chromaticB2,
        -3px 0px 2px $chromaticG2;
    animation:
        chromaticMove 4500ms ease infinite,
        pulse ease 3000ms infinite;
}
