// Comic webring page
#metro {

    .SubwaySection {
        background-color: #384035;
    }

    .SubwayGrid {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;

        &-header {
            border-top: 5px solid #FFF;
            position: relative;
            background: #fff;
            
    
        }
        &-headerInner {
            position: relative;
            height: $SPACE_SMALLER;
            background: #fff;
            @include breakpoint-tablet-up {
                // height: 60px;
            }
            @include breakpoint-desktop-up {
                // height: 100px;
            }
        }
        &-announcement {
            background-color: #C70000;
            font-size: 14px;
            color: $COLOR_INVERTED_TEXT;
            overflow: clip;
            max-width: 100vw;
            white-space: nowrap;
            
        }
        &-announcementInner {
            display: flex;
            align-items: center;
            gap: 4.8rem;
            width: max-content;
            height: 33px;
            padding: $SPACE_SMALL 0 $SPACE_SMALL 4.8rem;
            animation: marquee 50s linear infinite;

            @include breakpoint-mobile-only {
                animation: marquee 70s linear infinite;
            }
        }

        @keyframes marquee{
            to{
                transform: translateX(-50%);
            }
        }
        &-report {
            position: absolute;
            top: 40%;
            right: 10%;
            width: 40%;
            max-width: 430px;
            background-color: #fff;
            border-bottom-left-radius: $SPACE_SMALL;
            border-bottom-right-radius: $SPACE_SMALL;
            z-index: 1;
        }
        &-reportLink {
            display: inline-block;

            padding: 12px $SPACE_MEDIUM;
            background-color: #FFF;
            border-radius: $SPACE_SMALL;
            color: blue;
            text-decoration: underline;
        }

        &-logo {
            position: absolute;
            left: 10%;
            top: 40%;
            z-index: 1;
        }
        &-logoImage {
            width: 70px;
            vertical-align: bottom;
            aspect-ratio: 104/150;

            @include breakpoint-tablet-up {
                width: 105px;
            }
            @include breakpoint-desktop-up {
                width: 140px;
            }
        }

        &-body {
            position: relative;
            background: center / cover no-repeat url($IMAGE_DIR+'bg-subway.jpg');

            @include breakpoint-tablet-down {
                aspect-ratio: 4/3;
            }
            @include breakpoint-desktop-up {
                height: 810px;
            }
        }
        &-static {
            @include FULL_COVER;
            background-image: url($IMAGE_DIR+'static.png');
            background-size: auto 100%;
            background-position: center;
            background-repeat: no-repeat;
            mix-blend-mode: multiply;
            pointer-events: none;
        }
        &-live {
            position: absolute;
            right: 5%;
            bottom: 5%;

            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 4px;

            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            color: #FFF;
            text-transform: uppercase;
        }
        &-liveIndicator {
            display: inline-block;
            width: 16px;
            height: 16px;
            background-color: #F00;
            border-radius: 50%;
            animation: --live-blink steps(2, jump-both) 1s infinite;
        }

        &-comic {
            position: absolute;
            display: block;
            left: 50%;
            bottom: 20%;
            width: 15%;
            filter: drop-shadow(0 0.5vw 0.5vw rgba(0,0,0,.6)) saturate(0.5);
            transform: translateY(0);
            transition: all $TRANSITION_FAST;
            perspective: 800px;
            cursor: pointer;

            @include breakpoint-desktop-up {
                width: 12%;
            }
            &:hover {
                filter: drop-shadow(0 3vw 2vw rgba(0,0,0,.7)) saturate(1);
                transform: translateY(-2vw);
            }
        }
        &-comicInner {
            transform-style: preserve-3d;
            transform: translate(-50%, 50%) rotateX(75deg);
        }
        &-comicImage {
            width: 75%;
            aspect-ratio: 800/1131;
            vertical-align: bottom;
            transform: rotate(-40deg)
        }
    }

}
