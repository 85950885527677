// On-Lyne webring page
@keyframes --player-button-shimmer {
    0% {
        transform: translateX(0);
        filter: opacity(0);
    }
    10% {
        transform: translateX(100%);
        filter: opacity(1);
    }
    20% {
        transform: translateX(200%);
        filter: opacity(0);
    }
    100% {
        transform: translateX(200%);
        filter: opacity(0);
    }
}
#on-lyne {
    background: linear-gradient(to bottom, #E74073 0%, #FFFE27 100%);

    .MainSection {
        .Section-wrapper {
            overflow: unset;
        }
    }

    .PageTitleBlock {
        position: relative;
        width: 100%;

        &-pre,
        &-post {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            color: #000AFF;
        }
        &-pre {
            margin-bottom: $SPACE_SMALL;
        }
        &-logo {
            width: 100%;
        }
        &-logoImage {
            width: 100%;
        }
        &-post {
            text-align: right;
        }

        &--mobile {
            @include breakpoint-tablet-up {
                display: none;
            }
        }
        &--desktop {
            @include breakpoint-mobile-only {
                display: none;
            }
        }
    }

    .CharacterCard {
        position: absolute;

        filter: drop-shadow(0 4px 4px rgba(0,0,0,.45));
        transition: all $TRANSITION_FAST;
        cursor: pointer;

        &[data-index="1"] {
            top: 35%;
            left: 20%;
            transform: translate(-50%, -50%) rotate(-4.08deg);
            z-index: 2;
        }
        &[data-index="2"] {
            top: 30%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(1.4deg);
            z-index: 3;
        }
        &[data-index="3"] {
            top: 33%;
            left: 80%;
            transform: translate(-50%, -50%) rotate(-3deg);
            z-index: 4;
        }
        &[data-index="4"] {
            top: 75%;
            left: 35%;
            transform: translate(-50%, -50%) rotate(-6.09deg);
            z-index: 0;
        }
        &[data-index="5"] {
            top: 70%;
            left: 65%;
            transform: translate(-50%, -50%) rotate(1.5deg);
            z-index: 1;
        }

        &-image,
        &-overlay {
            width: 101px;
            aspect-ratio: 600/811;
            vertical-align: bottom;

            @include breakpoint-tablet-up {
                width: 123px;
            }
            @include breakpoint-desktop-up {
                width: 205px;
            }
            @include breakpoint-wide-up {
                width: 307px;
            }
        }
        &-title {
            position: absolute;
            left: $SPACE_SMALL;
            bottom: 0;
            right: 0;
            text-align: left;
            font-family: W95FA, "Courier New", monospace;
            text-transform: uppercase;
            color: $COLOR_TEXT;
        }
        &-overlay {
            @include FULL_COVER;
            filter: opacity(0);
            transform: scale(1);
            pointer-events: none;
        }
        &-anchor {
            position: absolute;
            height: 20%;
            bottom: 0;
            left: 0;
            right: 0;
        }
        &.is-inactive {
            filter: drop-shadow(0 4px 4px rgba(0,0,0,.45)) brightness(0.5);
        }
        &.is-active {
            filter: drop-shadow(0 8px 4px rgba(0,0,0,.45));
            transform: translate(-50%, -52%) rotate(0);
            z-index: 5;
        }
        &.is-glitchy {
            .CharacterCard-overlay {
                animation-name: --character-card-glitch-corner;
                animation-duration: 3s;
                animation-direction: normal;
                animation-fill-mode: both;
                animation-iteration-count: infinite;
            }
            &:hover {
                .CharacterCard-overlay {
                    animation-name: --character-card-glitch-corner-fast;
                    animation-duration: 250ms;
                    animation-direction: normal;
                    animation-fill-mode: both;
                    animation-iteration-count: infinite;
                }
            }
        }
        &.is-glitched {
            .CharacterCard-overlay {
                animation-name: --character-card-glitch-in;
                animation-duration: $TRANSITION_FAST;
                animation-direction: normal;
                animation-fill-mode: both;
                animation-iteration-count: 1;
            }
        }
        &.is-glitched-out {
            .CharacterCard-overlay {
                animation-name: --character-card-glitch-out;
                animation-duration: $TRANSITION_FAST;
                animation-direction: normal;
                animation-fill-mode: both;
                animation-iteration-count: 1;
            }
        }

        &:after {
            content: "";
            @include FULL_COVER;
            pointer-events: none;
            border: solid 4px darkorchid;
            opacity: 0.9;
            transition: border $TRANSITION_FAST;
            @include breakpoint-tablet-up {
                border-width: 8px;
            }
            @include breakpoint-desktop-up {
                border-width: 12px;
            }
        }

        &:hover {
            z-index: 6;

            &:after {
                border-color: white;
            }
        }
    }
    .CallingCard {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 120px;
        aspect-ratio: 600/811;

        transform: translate(-50%, -50%) rotate(-7deg);
        transition: opacity $TRANSITION_FAST;
        perspective: 800px;
        opacity: 0;
        pointer-events: none;
        z-index: 7;
        filter: drop-shadow(0 8px 8px rgba($COLOR_BLACK, 0.5));

        @include breakpoint-desktop-up {
            width: 240px;
        }

        &-inner {
            @include FULL_COVER;
            transition: transform $TRANSITION_FAST;
            transform-style: preserve-3d;

            &:before,
            &:after {
                content: "";
                @include FULL_COVER;
                border-radius: $SPACE_SMALL;
                backface-visibility: hidden;
                @include breakpoint-tablet-up {
                    border-width: 8px;
                }
                @include breakpoint-desktop-up {
                    border-width: 12px;
                }
            }
            &:before {
                background: center / cover no-repeat url(#{$IMAGE_DIR}card-afc96cb89dc2.jpg);
            }
            &:after {
                background: center / cover no-repeat url(#{$IMAGE_DIR}card-cdaea04c2894.png);
                transform: rotateY(180deg);
            }
        }
        &.is-active {
            opacity: 1;
            pointer-events: all;
        }
        &.is-flipped {
            .CallingCard-inner {
                transform: rotateY(-180deg);
            }
        }
    }

    .MainGrid {
        display: grid;
        padding-bottom: $SPACE_LARGER;

        @include breakpoint-mobile-only {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
        }
        @include breakpoint-tablet-up {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-rows: auto 1fr;
            gap: 0 24px;
        }
        @include breakpoint-desktop-up {
            grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
        }

        &-watermark {
            @include FULL_COVER;
            background: top / 100% no-repeat url(#{$IMAGE_DIR + 'logo-on-lyne.svg'});
            opacity: 0.1;

            @include breakpoint-mobile-only {
                display: none;
            }
        }

        &-cards {
            position: relative;
            aspect-ratio: 1;

            @include breakpoint-tablet-up {
                grid-row: 1 / span 2;
                grid-column: 1;
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 5%;
                right: 5%;
                background-image: linear-gradient(90deg, white 1px, transparent 2px, transparent 10px);
                background-size: 10px 100%;
                background-repeat: repeat-x;
                mask-image: linear-gradient(to bottom, transparent, black 40%, black 60%, transparent 100%);
            }
        }
        &-pages {
            position: relative;
            padding: 24px 0;

            @include breakpoint-tablet-up {
                grid-row: 2;
                grid-column: 2;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
            }
            @include breakpoint-desktop-up {
                padding: $SPACE_LARGE 0;
            }
        }
        &-player {
            position: relative;
            padding-top: 24px;

            @include breakpoint-mobile-only {
                grid-row: 1;
            }
            @include breakpoint-tablet-up {
                grid-row: 1;
                grid-column: 2;
            }
            @include breakpoint-desktop-up {
                padding-top: $SPACE_LARGE;
            }
        }
    }

    .MainGridPage {
        display: none;
        font-family: $FONT_FAMILY_SERIF;
        --font-family-body: #{$FONT_FAMILY_SERIF};
        line-height: 1.15;

        &-logo,
        &-subtitle,
        &-description {
            margin-bottom: 24px;
            @include breakpoint-desktop-up {
                margin-bottom: $SPACE_LARGE;
            }
        }
        &-title {
            color: $COLOR_INVERTED_TEXT;
            font-size: 40px;
            font-weight: 700;

            @include breakpoint-desktop-up {
                font-size: 56px;
            }
        }
        &-subtitle {
            color: $COLOR_INVERTED_TEXT;
            font-size: 24px;
            @include breakpoint-desktop-up {
                font-size: 40px;
            }
        }
        &-description {
            position: relative;
            padding: 24px;
            color: $COLOR_TEXT;
            background-color: $COLOR_BACKGROUND;
            border-radius: $SPACE_MEDIUM;
            filter: drop-shadow($SPACE_SMALLER $SPACE_SMALLER 0 rgba(0,0,0,0.25));
            z-index: 1;

            p {
                line-height: 1.15;
            }
        }
        &-descriptionOverlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            min-height: 100%;
            padding: 24px;
            background-color: $COLOR_BACKGROUND;
            border-radius: $SPACE_MEDIUM;
            overflow: hidden;

            filter: opacity(0);
            transform: scale(1);
            pointer-events: none;
        }
        &-footer {
            padding: 24px;
            color: $COLOR_TEXT;
            background-color: #C2EBFF;
            border-radius: $SPACE_MEDIUM;
            filter: drop-shadow($SPACE_SMALLER $SPACE_SMALLER 0 rgba(0,0,0,0.25));
        }

        strong {
            font-weight: bold;
        }

        &.is-active {
            display: block;
        }
        &.is-glitched {
            .MainGridPage-descriptionOverlay {
                animation-name: --character-card-glitch-in;
                animation-duration: $TRANSITION_FAST;
                animation-direction: normal;
                animation-fill-mode: both;
                animation-iteration-count: 1;
            }
        }
        &.is-glitched-out {
            .MainGridPage-descriptionOverlay {
                animation-name: --character-card-glitch-out;
                animation-duration: $TRANSITION_FAST;
                animation-direction: normal;
                animation-fill-mode: both;
                animation-iteration-count: 1;
            }
        }
    }

    .MainGridUnderlinedText {
        span {
            text-decoration: underline;
        }
    }

    .MediaPlayerText {
        position: relative;
        text-align: right;

        &-inner {
            position: relative;
            display: inline-block;
            background: #27253B;
            border-right: solid 1px #000;
            border-bottom: solid 1px #000;
            border-top: solid 1px #656475;
            border-left: solid 1px #656475;
            font-family: W95FA, "Courier New", monospace;
            color: #FFFE27;
            --color-link: #FFFE27;
            --color-link-hover: #FFFE27;
            padding: 8px 8px 8px 32px;
            mask-image: linear-gradient(50deg, transparent 24px, black 25px);
            cursor: pointer;
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                right: 100%;
                background: linear-gradient(50deg, transparent 32px, white 48px, white 64px, transparent 80px);
                opacity: 0.3;
                animation-name: --player-button-shimmer;
                animation-duration: 3s;
                animation-fill-mode: both;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
        }
    }
    .MediaPlayer {
        position: relative;

        background: linear-gradient(90deg, #27253B 0%, #38375C 45%, #27253B 100%);
        border-top: solid 1px #656475;
        border-left: solid 1px #656475;
        border-right: solid 1px #000;
        border-bottom: solid 1px #000;
        padding: $SPACE_SMALL;

        &-audio {
            display: none;
        }

        &-divider {
            height: 3px;
            background-color: #000;
            border-top: solid 1px #AAA;
            border-bottom: solid 1px #AAA;
        }

        &-inner {
            display: grid;
            grid-template-columns: 1fr auto auto;
            gap: $SPACE_SMALL;
            margin-top: $SPACE_SMALL;
        }

        &-track,
        &-duration {
            font-family: W95FA, "Courier New", monospace;
            background-color: #000;
            border: solid 1px transparent;
            border-right-color: #7E7F84;
            border-bottom-color: #7E7F84;
            color: #4CFA57;
            padding: $SPACE_SMALLER;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            &.is-flicker {
                color: rgba(#4CFA57, 0.5);
            }
        }
        &-duration {
            width: 48px;
        }
        &-buttons {
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: $SPACE_THIN;
        }
        &-button {
            aspect-ratio: 1;
            background-color: #BECCD2;
            border-top: solid 1px #E0EAED;
            border-left: solid 1px #E0EAED;
            border-right: solid 1px #2A3038;
            border-bottom: solid 1px #2A3038;
            cursor: pointer;

            .Icon {
                height: 28px;
                width: 28px;
                vertical-align: bottom;
                filter: invert(50%) sepia(6%) saturate(966%) hue-rotate(166deg) brightness(91%) contrast(84%);
            }

            &.is-pressed {
                background-color: #6A7884;

                .Icon {
                    filter: invert(96%) sepia(3%) saturate(1123%) hue-rotate(149deg) brightness(87%) contrast(87%);
                }
            }
        }
    }
    .MediaPlaylist {
        position: relative;
        font-family: W95FA, "Courier New", monospace;

        &-title {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            align-items: center;
            gap: $SPACE_SMALLER;
            padding: $SPACE_SMALLER 0;
        }
        &-titleText {
            color: #FFFE27;
            font-size: 12px;
            white-space: nowrap;
            padding-top: 2px;
        }
        &-divider {
            height: 3px;
            background-color: #000;
            border-top: solid 1px #AAA;
            border-bottom: solid 1px #AAA;
        }

        &-list {
            background-color: $COLOR_BLACK;
            border-right: solid 1px #7E7F84;
            border-bottom: solid 1px #7E7F84;
            color: #4CFA57;
            text-transform: uppercase;
            padding: $SPACE_SMALLER;
        }
        &-entry {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            gap: $SPACE_SMALL;

            cursor: pointer;

            &:hover,
            &.is-active {
                background-color: rgba($COLOR_WHITE, 0.15);
            }
        }
        &-entryTitle {

        }
        &-entryTime {
            text-align: right;
        }
    }

    .PlatformsGrid {
        position: relative;
        max-width: 550px;
        margin: 0 auto 64px;
        padding: 8px 12px 12px;
        background: linear-gradient(to bottom, #C4C4C4, #969698);
        border: solid 1px #E2E2E2;
        border-radius: 8px;
        box-shadow: 0 4px 4px rgba(black, 0.25);
        font-family: Arial, Helvetica, sans-serif;

        &-title {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 8px;

            &:before {
                content: "";
                display: inline-block;
                height: 16px;
                width: 16px;
                margin-right: 8px;
                border: solid 4px #000;
                border-radius: 50%;
                box-sizing: border-box;
                vertical-align: text-bottom;
            }
        }

        &-inner {
            position: relative;
            background: linear-gradient(to bottom, #ECEEE1 42%, #D7DBBF 50%, #D7DBC0 100%);
            border: solid 1px #66685A;
            border-radius: 8px;
        }
        &-items {
            position: relative;
            padding: 8px 16px;
            display: grid;
            grid-template-columns: repeat(auto-fill, 150px);
            list-style: disc;
            --color-link: black;
            --color-link-hover: black;
            line-height: 1.2;
        }
        &-item {
            margin-left: 1em;
        }
    }
}
