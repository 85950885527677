#dangerousSighting {
    
    overflow: hidden;
    background-color: #EFEFEF;
    .DangerousSection {
        background-color: #384035;
    }

    .DangerousGrid {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        margin-bottom: 100px;
        &-header {
            position: relative;
            background: #EFEFEF;
            border-top: 5px solid #FFF;
        }
        &-headerInner {
            position: relative;
            height: $SPACE_SMALLER;
            background: #fff;
        }
        &-announcement {
            background-color: #C70000;
            font-size: 14px;
            color: $COLOR_INVERTED_TEXT;
            overflow: clip;
            max-width: 100vw;
            white-space: nowrap;
            
        }
        &-announcementInner {
            display: flex;
            align-items: center;
            gap: 4.8rem;
            width: max-content;
            height: 33px;
            padding: $SPACE_SMALL 0 $SPACE_SMALL 4.8rem;
            animation: marquee 50s linear infinite;

            @include breakpoint-mobile-only {
                animation: marquee 70s linear infinite;
            }
        }

        @keyframes marquee{
            to{
                transform: translateX(-50%);
            }
        }
        &-logo {
            position: absolute;
            left: 10%;
            top: 20px;
            z-index: 1;
        }
        &-logoImage {
            width: 70px;
            vertical-align: bottom;
            aspect-ratio: 104/150;

            @include breakpoint-tablet-up {
                width: 105px;
            }
            @include breakpoint-desktop-up {
                width: 140px;
            }
        }

        &-body{
            position: relative;
            background-color: #EFEFEF;
            display: flex;
            flex-direction: column;
            align-items: center;

            background: 
                linear-gradient(to bottom, transparent 45px, #EFEFEF 160px), 
                url($IMAGE_DIR+'bg-road-map.jpg') no-repeat top center; 
            
            background-size: 100vw auto;

            @include breakpoint-tablet-up{
                background: 
                    linear-gradient(to bottom, transparent 45px, #EFEFEF 452px), 
                    url($IMAGE_DIR+'bg-road-map.jpg') no-repeat top center;
                background-size: cover;
            }

        }

        &-yellowBanner{
            margin-top: 175px;
            background: linear-gradient(90.01deg, #F4BE4B 44.1%, #F4BE4B 99.99%);
            width: 350px;
            height: 129px;
            border-radius: 3px;
            border: 2px solid rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            @include breakpoint-tablet-up{
                width: 760px;
                height: 99px;
                margin-top: 230px;
            }
            @include breakpoint-desktop-up{
                width: 820px;
            }
        }

        &-returnButton{
            position: absolute;
            top: -40px;
            left: 0px;
            font-family: Verdana;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.45px;
            text-align: left;
            text-decoration-line: underline;
            text-decoration-style: solid;

        }

        &-yellowBannerTextContainer{
            display: flex;
            width: 294px;
            height: 58px;
            justify-content: center;
            align-items: flex-start;;
            flex-direction: column;

            @include breakpoint-tablet-up{
                width: 90%;
            }
        }

        &-yellowBannerTitle{
            font-family: Verdana;
            font-size: 20px;
            font-weight: 700;
            line-height: 24.31px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }

        &-yellowBannerImage{
            position: absolute;
            right: -15px;
            top: -50px;
            width: 134px;
            height: 134px;
            transform: rotate(-3deg);
        }
        &-textBox{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            width: 350px;

            @include breakpoint-tablet-up{
                width: 760px;
            }
            @include breakpoint-desktop-up{
                width: 820px;
            }
        }

        &-Header{
            margin-top: 20px;
            font-family: Verdana;
            font-size: 16px;
            font-weight: 700;
            line-height: 19.45px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }

        .divider{
            height: 1px;
            width: 350px;
            background: black;

            @include breakpoint-tablet-up{
                width: 760px;
            }
            @include breakpoint-desktop-up{
                width: 820px;
            }
        }
        &-Subtext{
            margin-top: 20px;
            font-family: Verdana;
            font-size: 14px;
            font-weight: 400;
            line-height: 17.01px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-bottom: 20px; 
        }


        &-reportGridSuccess{
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat(1, 4fr); 
            gap: 10px; 
            width: 350px;
            display: none;
            
            @include breakpoint-tablet-up{
                width: 760px;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(4, auto); 
                grid-auto-flow: column;
            }
            @include breakpoint-desktop-up{
                width: 820px;
            }
        }

        &-reportGridSuccessThanks{
            width: 295px;
            height: 290px;
            background-image: url($IMAGE_DIR+'thank-you-message.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left;

            @include breakpoint-tablet-up{
                margin-top: -50px;
                margin-bottom: -50px;
                width: 435px;
                height: 427px;
                background-position: center;
                justify-self: center;
            }
            @include breakpoint-desktop-up{
                width: 580px;
            }
        }

        &-reportGridSuccessTextContainer{
            display: flex;
            flex-direction: column;
            @include breakpoint-tablet-up{
                width: 520px;
            }
    
        }

        &-reportGridSuccessHeader{
            font-family: Verdana;
            font-size: 20px;
            font-weight: 700;
            line-height: 24.31px;
            text-align: left;
        }
        &-reportGridSuccessText{
            margin-top: 20px;
            font-family: Verdana;
            font-size: 14px;
            font-weight: 400;
            line-height: 17.01px;
            text-align: left;
        }

        &-mindTheGapBanner{
            margin-top: 20px;
            height: 156px;
            width: 350px;
            background-image: url($IMAGE_DIR+'mind-the-gap-ad-mobile.jpg');
            background-size: cover;
            background-position: center;

            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            @include breakpoint-tablet-up{
                background-image: url($IMAGE_DIR+'mind-the-gap-ad-desktop.jpg');
                grid-column: 2 / 2;
                grid-row: 1 / 1;
                width: 230px;
                height: 400px;
                margin-top: 0px;
            }
        }
        &-mindTheGapBannerHeader{
            margin-left: 160px;
            font-family: Verdana;
            font-size: 20px;
            font-weight: 700;
            line-height: 29.17px;
            text-align: left;

            @include breakpoint-tablet-up{
                margin-left: 20px;
                margin-top: 100px;
            }
        }

        &-mindTheGapBannerSubtext{
            margin-left: 160px;
            width: 180px;
            margin-top: 8px;
            font-family: Verdana;
            font-size: 12px;
            font-weight: 400;
            line-height: 17.01px;
            text-align: left;
            
            strong{
                font-weight: bold;
            }

            @include breakpoint-tablet-up{
                margin-left: 20px;
                width: 200px;
            }
        }

        &-loopyBanner{
            height: 175px;
            width: 350px;
            background-image: url($IMAGE_DIR+'loopy-ad-mobile.jpg');
            background-size: cover;
            background-position: center;
            position: relative;

            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            
            @include breakpoint-tablet-up{
                background-image: url($IMAGE_DIR+'loopy-ad-desktop.jpg');
                grid-column: 2 / 2;
                grid-row: 2 / 4;
                width: 230px;
                height: 730px;
                align-items: center;
                justify-content: flex-start;
            }
        }

        &-loopyBannerHeader{
            margin-left: 180px;
            font-family: Times New Roman;
            font-size: 36px;
            font-weight: 700;
            line-height: 41.4px;
            text-align: left;
            color: rgba(255, 255, 255, 1);
            
            @include breakpoint-tablet-up{
                text-align: center;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                top: 80px;
                margin-left: 0px;
                margin-top: 155px;
                width: 100%;
            }
        }
        &-loopyBannerText{
            margin-left: 180px;
            font-family: Times New Roman;
            font-size: 16px;
            font-weight: 700;
            line-height: 18.4px;
            text-align: left;   
            display:none;

            @include breakpoint-tablet-up{
                text-align: center;
                width: 100%;
                margin-left: 0px;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                bottom: 0;
                background: rgba(249, 122, 10, 1);
                padding: 10px;
                display: block;
            }
        }
        &-loopyBannerDesc{
            display: none;
            text-align: right;
            @include breakpoint-tablet-up{
                width: 95%;
                text-align: right;
                display: block;
                position: absolute;
                left: 50%;
                top: 535px;
                transform: translate(-50%, 0);
            }   
        }

        &-loopyBannerDescHeader{
            font-family: Times New Roman;
            font-size: 20.18px;
            font-weight: 700;
            line-height: 23px;
            text-align: right;
            @include breakpoint-tablet-up{
                margin-bottom: 15px;
            }
        }

        &-loopyBannerDescSub{
            display: none;
            font-family: Times New Roman;
            font-size: 14.38px;
            font-weight: 700;
            line-height: 16.54px;
            text-align: center;
            margin-top: 5px;
            span{
                color: rgba(244, 190, 75, 1);
            }
            @include breakpoint-tablet-up{
                width: 100%;
                display: block;
                text-align: right;
                margin-right: 10px;
            }
        }


        &-reportGrid {
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat(1, 18fr); 
            gap: 10px; 
            width: 350px;

            @include breakpoint-tablet-up{
                width: 760px;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(16, auto); 
                grid-auto-flow: column;
            }
            @include breakpoint-desktop-up{
                width: 820px;
            }
        }

        &-reportGridItem {
            background: linear-gradient(180deg, #5EB5E2 0%, #5EB5E2 100%);
            width: 350px;
            height: auto;
            padding: 16px 0px 0px 0px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);  
            grid-template-rows: repeat(2, auto);   
            grid-template-columns: 1.3fr 2fr 2fr;
            @include breakpoint-tablet-up{
                width: 550px;
                height: 90px;
                grid-template-columns: 0.7fr 1fr 2fr;
            }
            @include breakpoint-desktop-up{
                width: 610px;
                height: 90px;
                grid-template-columns: 0.7fr 1fr 2fr;
            }
        }

        &-reportGridItemPfp{
            grid-row: span 2;
            width: 59px;
            height: 59px;
            justify-self: center;
            border: 2px solid black;
        }

        &-reportGridItemDescBox{
            grid-column: span 2;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            margin-bottom: 15px;
            margin-top: 5px;
            
            @include breakpoint-tablet-up{
                justify-content: flex-start;
            }
        }

        &-reportGridItemDesc{
            font-family: Verdana;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.58px;
            text-align: left;
            padding: 6px 10px;
        }

        &-reportGridItemName{
            font-family: Verdana;
            font-size: 14px;
            font-weight: 700;
            line-height: 17.01px;
            text-align: left;
            color: white;
        }

        &-reportGridItemTime{
            font-family: Verdana;
            font-size: 14px;
            font-weight: 700;
            line-height: 17.01px;
            text-align: left;
            color: white;
        }

        &-weCareBanner{
            width: 350px;
            height: 156px;
            margin-top: 50px;
            background-image: url($IMAGE_DIR+'we-care-ad-mobile.jpg');
            background-size: cover;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            @include breakpoint-tablet-up{
                grid-column: 2 / 2;
                grid-row: 1 / 6;
                margin-top: 0px;
                background-image: url($IMAGE_DIR+'we-care-ad-desktop.jpg');
                width: 200px;
                height: 400px;
            }
        }

        &-weCareBannerHeader{
            margin-left: 110px;
            max-width: 340px;
            font-family: Verdana;
            font-size: 20px;
            font-weight: 700;
            line-height: 29.17px;
            text-align: left;
            color: white;

            @include breakpoint-tablet-up{
                margin-left: 20px;
                margin-top: 80px;
            }
        }

        &-weCareBannerSubtext{
            margin-left: 110px;
            width: 224px;
            margin-top: 12px;
            font-family: Verdana;
            font-size: 12px;
            font-weight: 400;
            line-height: 17.01px;
            text-align: left;
            color: white; 
            
            @include breakpoint-tablet-up{
                margin-left: 20px;
                width: 130px;
            }
        }

        &-bbpBanner{
            width: 350px;
            height: 175px;
            background-image: url($IMAGE_DIR+'big-bytes-pizza-ad-mobile.jpg');
            background-size: cover;
            background-position: center;
            margin-top: 10px;
            @include breakpoint-tablet-up{
                grid-column: 2 / 2;
                grid-row: 5 / 12;
                background-image: url($IMAGE_DIR+'big-bytes-pizza-ad-desktop.jpg');
                width: 200px;
                height: 550px;
                margin-top: 30px;
            }
        }
        
        &-submitRequest{
            margin-top: 30px;
            width: 350px;
            height: 340px;
            position: relative;
            margin-left: 0;
            overflow-x: auto;

            @include breakpoint-tablet-up{
                width: 760px;
            }
            @include breakpoint-desktop-up{
                overflow-x: visible;
                width: 820px;
            }
        }

        &-submitRequestInner{
            width: 820px;
            border: 1px solid rgba(42, 46, 36, 0.5);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 10px;
        }

        &-submitRequestInnerHeader{
            font-family: Verdana;
            font-size: 16px;
            font-weight: 700;
            line-height: 19.45px;
            text-align: left;
            margin-top: 20px;
        }

        &-submitRequestInnerBanner{
            margin-top: 20px;
            width: 798px;
            height: 51px;
            background-image: url($IMAGE_DIR+'table-header.jpg');
            background-size: cover;
        }
        &-submitRequestInnerForm{
            margin-top: 5px;
            margin-left: 15px;
        }
        &-inputGrid {
            display: grid;
            grid-template-columns: repeat(6, 1fr); 
            grid-template-rows: repeat(5, auto);
            grid-auto-flow: column;
            column-gap: 2px; 
            row-gap: 7px;
        }

        &-dropdown {
            width: 123px; 
            height: 27px;
            padding: 0;
            padding-left: 5px;
            font-size: 14px; 
            border: 1px solid #ccc; 
            background-color: #fff; 
            margin: 0;
            cursor: pointer;
        }

        &-inputText{
            height: 27px;
            width: 130px;
            font-family: Verdana;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.58px;
            text-align: left;
            display: flex;
            align-items: center;
        }

        &-submitRequestInnerButton {
            font-family: Verdana;
            text-transform: none;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.45px;
            text-align: center;
            color: black !important;
            background: linear-gradient(182.38deg, #FFFFFF 2%, #999999 182.61%);
            border: 1px solid rgba(0, 0, 0, 0.25) !important;
            border-radius: 2px;
            height: 27px;
            width: 90px;
            text-align: center;
            padding: 0;
            box-shadow: inset 0 1px 0 #fff, 0 1px 2px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            margin-top: 15px;
            margin-bottom: 20px;
            @include breakpoint-desktop-up{
                margin-left: 44%;
            }
        }

        &-submitRequestInnerError{
            margin-bottom: 20px;
            display: flex;
            display: none;
            justify-content: flex-start;
            flex-direction:column;
        }
        &-submitRequestInnerErrorHeader{
            font-family: Times New Roman;
            font-size: 24px;
            font-weight: 700;
            line-height: 27.6px;
            text-align: left;
            color: rgba(223, 10, 10, 1);
        }
        &-submitRequestInnerErrorSubtext{
            margin-top: 10px;
            font-family: Times New Roman;
            font-size: 16px;
            font-weight: 400;
            line-height: 18.4px;
            text-align: left;
        }
    }

}