.bigbytes-wrapper{
    background-color: black;
}
$IMAGE_DIR: '/images/promo/1999/';
#bigbytespizza{ 
    position: relative;
    width: 1920px;
    max-width: 100%;
    margin: 0 auto;
    
    overflow: hidden;
    .splat {
        position: absolute;
        pointer-events: none;
        user-select: none;
        width: 250px;
        height: 250px;
        opacity: 1;
        z-index: 10;
        animation: fly-in 350ms both 1 cubic-bezier(.16,.77,.2,1);
    }

    .splat-zindex-3 {
        z-index: 3;
    }

    .splat-is-fadeout {
        animation: fadeOut 0.5s forwards;
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    /* Animation for the splat flying in */
    @keyframes fly-in {
        from {
            opacity: 0;
            transform: translate(-100%, -100%) scale(2);
        }
        to {
            opacity: 1;
            transform: translate(0) scale(1);
        }
    }

    .MastheadSection{
        height: 500px;
        width: 100%;

        @include breakpoint-tablet-only{
            height: 400px;
        }

        @include breakpoint-mobile-only{
            height: 260px;
        }
        .MastheadSection-Background{
            position: relative;
            background-size: cover;
            background-position: center;    
            background: rgba(150, 1, 7, 1);
            height: 500px;
        }

        .MastheadOverlay{
            
            .pizza-logo{
                width: 653px;
                height: auto;
                position: absolute; 
                right: 25%;
                top: 20%;
                z-index: 2;

                @include breakpoint-tablet-only{
                    width: 434px;
                    top: 25%;
                }

                @include breakpoint-mobile-only{
                    width: 250px;
                    right: 50%;
                    transform: translateX(50%);
                }
            }
            .dot-overlay{
                width: 725px;
                height: auto;
                position: absolute;
                top: 12%;
                right: 0px;

                @include breakpoint-tablet-only{
                    width: 450px;
                }

                @include breakpoint-mobile-only{
                    width: 400px;
                }
            }
            .pizza-curve{
                width: 540px;
                height: auto;
                position: absolute;
                left: 0%;
                top: 10%;
                z-index: 0;

                @include breakpoint-tablet-only{
                    width: 434px;
                    left: -4%;
                    top: -30%;
                }

                @include breakpoint-mobile-only{
                    width: 300px;
                    top: -35%;
                    left: -8%;
                }
            }

        }
    }
    
    .BigBytesBanner {
        background: white;
        height: 69px;
        display: flex;
        justify-content: right;
        align-items: center;
        z-index: 1;
        gap: 40px;
        max-width:100%;
        position: relative;
        &-Text{
            font-family : "Impact", 'Roboto', sans-serif;
            font-weight: 900;
            font-size: 50px;
            text-align: center;
            color: rgba(229, 64, 11, 1);
            white-space: nowrap;
            text-shadow: 
            -2px -2px 0 black, 
            2px -2px 0 black,
            -2px  2px 0 black, 
            4px  4px 0 black;

            span{
                margin-right: 20px;
            }
            @include breakpoint-tablet-only{
                font-size: 40px;
            }
            @include breakpoint-mobile-only{
                font-size: 32px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                width: 100%;
                white-space: normal ;
                line-height: 34px;
                display: flex;
                flex-direction: column;

                span{
                    margin-right: 0px;
                }
            }
        }
        &-Left{
            width: 12%;
            height: 68px; 
            background-image: url(#{$IMAGE_DIR}tile.svg);
            background-repeat: repeat; 
            background-size: auto; 

            @include breakpoint-mobile-only{
                display: none;
            }
        }

        &-Right{
            width: 62%; 
            height: 68px; 
            background-image: url(#{$IMAGE_DIR}tile.svg);
            background-repeat: repeat; 
            background-size: auto; 

            @include breakpoint-tablet-only{
                width: 55%;
            }

            @include breakpoint-mobile-only{
                width: 100%;
            }
        }
    }

    
    
    .BodySection {
        position: relative;
        background: rgba(43, 131, 22, 1);
        overflow: hidden;

        @include breakpoint-tablet-only{
            height: 2080px;
        }

        @include breakpoint-mobile-only{
            height: 1990px;
        }
        .Menu{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
        }
        // Menu Header Section
        .Menu-Header{
            display: flex;
            justify-content: center;
            height: 30%;

            @include breakpoint-desktop-up{
                width: 1632px;
            }
            @include breakpoint-mobile-only{
                width: 343px;
            }
        }

        .Menu-Content{
            width: 40%;
            margin-left: 10%;
            margin-top: 10%;
            z-index: 3;
            @include breakpoint-mobile-only{
                margin-left: 15%;
            }
        }

        .Menu-Title{
            font-family : "Impact", 'Roboto', sans-serif;
            font-weight: 900;
            font-size: 80px;
            text-align: center;
            text-shadow: 
                -4px -4px 0 black, 
                4px -4px 0 black,
                -4px  4px 0 black, 
                4px  4px 0 black;
            display: flex;
            white-space: nowrap;
            gap: 10px;
            transform: rotate(-2.5deg);
            margin-bottom: 2rem;

            @include breakpoint-tablet-only{
                font-size: 50px;
            }

            @include breakpoint-mobile-only{
                flex-direction: column;
                font-size: 50px;
                position: relative;
                z-index: 3;
            }


            &-Red{
                color: rgba(229, 64, 11, 1);
                display: flex;
                gap: 1rem;
                @include breakpoint-mobile-only{
                    flex-direction: column;
                    gap: 0px;
                }
                span{       
                    color: white;
                }
            }
            
        

           
        }
        
        .Menu-Subtext{
            font-family: 'Roboto', sans-serif;
            font-size: 22px;
            color: white;

            @include breakpoint-mobile-only{
                width: 333px;
                padding-bottom: 20px;
            }
        }

        .pizza-slice{
            width: 650px;
            height: 650px;
            z-index: 2;
            margin-top: -30px;
            position: relative;
            pointer-events: none;
            user-select: none;
            @include breakpoint-tablet-only{
                width: 418px;
                height: 418px;
            }

            @include breakpoint-mobile-only{
                width: 250px;
                height: 250px;
            }
        }

        // Menu Items section
        .MenuItems{
            display: flex;
            width: 1200px;    
            height: 40%;

            @include breakpoint-mobile-only {
                justify-content: center;
                height: 940px;
                width: 343px;
            }
            @include breakpoint-tablet-only {
                height: 1120px;
                width: 710px;
                gap: 20px;
                padding-top: 30px;
            }
            @include breakpoint-desktop-up {
                margin-top: -75px;
                padding-bottom: 75px;
            }

            &-Grid {
                height: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(6, auto);
                gap: 20px;

                @include breakpoint-tablet-only {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(6, auto); 
                    overflow-y: hidden;
                }

                @include breakpoint-mobile-only {
                    grid-template-columns: repeat(1, 1fr);
                    grid-template-rows: repeat(2, auto); 
                    overflow-y:hidden;
                }

                .Pinkbox {
                    position: relative;
                    width: 90%;
                    height: 156px;
                    border-radius: 8px;
                    background: linear-gradient(180deg, #FFFFFF 0%, #FA6B7D 100%);
                    border: 1px solid black;
                    display: flex;
                    align-items: center;
                    padding-left: 50px;
                    gap: 100px;
                    justify-self: center;

                    @include breakpoint-mobile-only{
                        width: 100%;
                        height: 320px;
                        padding-left: 0px;
                        flex-direction: column;
                        gap: 20px;
                        grid-column: 1 / span 1;
                        grid-row: 2;
                    }
                    @include breakpoint-tablet-down {
                        width: 100%;
                        height: 150px;
                        grid-column: 1 / span 2;
                        grid-row: 3;
                        justify-content: flex-start;
                        gap: 50px;
                    }
                    @include breakpoint-desktop-up {
                        grid-column: 1 / span 3;
                        grid-row: 4;
                    }

                    .pizza-overlay{
                        position: absolute;
                        width: 200px;
                        height: auto;
                        top: -80px;
                        left: -30px;

                        @include breakpoint-tablet-down{
                            display: none;
                        }
                    }

                    .cd-overlay{
                        position: absolute;
                        width: 128px;
                        height: auto;
                        bottom: 10%;
                        right: -30px;

                        @include breakpoint-tablet-down{
                            width: 80px;
                            bottom: 0px;
                            right: 0px;
                        }
                    }

                    &-Header{
                        width: 85%;
                        position: relative;
                        padding-bottom: 10px;
                    
                        .h1{
                            font-family : "Impact", 'Roboto', sans-serif;
                            font-weight: 900;
                            font-size: 37px;
                            color: rgba(229, 64, 11, 1);
                            padding-top: 10px;
                            padding-bottom: 10px;
                            text-shadow: 
                            -2px -2px 0 black, 
                            2px -2px 0 black,
                            -2px  2px 0 black, 
                            4px  4px 0 black;
                        }

                        .h2{
                            font-family : "Impact", 'Roboto', sans-serif;
                            font-weight: 900;
                            font-size: 32px;
                            color: rgba(0, 237, 126, 1);
                            text-shadow: 
                            -2px -2px 0 black, 
                            2px -2px 0 black,
                            -2px  2px 0 black, 
                            4px  4px 0 black;
                            text-align: right;
                            right: 0px;

                            position: absolute; 
                            bottom: 0px;
                            right: 0px;
                        }
                        .h3{
                            font-family: 'Roboto', sans-serif;
                            font-size: 16px;
                            
                        }

                        @include breakpoint-tablet-down{
                            .h1{
                                font-size: 30px;
                            }
                            .h2{
                                font-size: 28px;
                            }
                            .h3{
                                font-size: 13px;
                            }
                        }

                        @include breakpoint-mobile-only{
                            padding-top: 15px;
                            .h3{
                                max-width: 60%;
                            }
                        }
                    }

                    &-cdText{
                        width: 85%;
                        z-index: 2;
                        
                        @include breakpoint-desktop-up{
                            padding-left: 40px;
                        }
                        @include breakpoint-tablet-only{
                            width: 60%;
                        }

                        .h1{
                            font-family : "Impact", 'Roboto', sans-serif;
                            font-weight: 900;
                            font-size: 37px;
                            color: white;
                            padding-bottom: 10px;
                            text-shadow: 
                            -2px -2px 0 black, 
                            2px -2px 0 black,
                            -2px  2px 0 black, 
                            4px  4px 0 black;
                        }

                        .h3{
                            font-family: 'Roboto', sans-serif;
                            font-size: 16px;
                        }

                         @include breakpoint-tablet-down{
                            .h1{
                                font-size: 30px;
                            }
                            .h2{
                                font-size: 28px;
                            }
                            .h3{
                                font-size: 13px;
                            }
                        }
                    }
                    
                }
            }

            &-Grid > div{
                background-color: white;
                border-radius: 8px;
                border: 1px solid black;
                display: flex;
                justify-content: center;
                height: 270px;
                width: 393px;

                @include breakpoint-tablet-only{
                    width: 343px;
                    height: 290px;
                }

                @include breakpoint-mobile-only{
                    width: 343px;
                    height: 290px;
                }

                .textContainer{
                    width: 85%;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                
                // Body Text
                .h3{
                    font-family: 'Roboto', sans-serif;
                    font-size: 12px;
                }
                
                // Header Text
                .h1{
                    font-family : "Impact", 'Roboto', sans-serif;
                    font-weight: 900;
                    font-weight: bold;
                    font-size: 32px;
                    color: black;
                }
                
                // Price Text
                .h2{
                    font-family : "Impact", 'Roboto', sans-serif;
                    font-weight: 900;
                    font-size: 32px;
                    color: rgba(0, 237, 126, 1);
                    text-shadow: 
                    -2px -2px 0 black, 
                    2px -2px 0 black,
                    -2px  2px 0 black, 
                    4px  4px 0 black;
                    text-align: right;
                    right: 0px;
                    padding-top: 15px;
                }

                .h4{
                    font-family: 'Roboto', sans-serif;
                    font-size: 14px;
                    color: red;
                    padding-bottom: 15px;
                }


            }
        } 

.Menu-HandsOn {

    position:relative;

    @include breakpoint-desktop-up {
        display: flex;
        width: 1200px;
        height: 500px;
    }

    @include breakpoint-tablet-only{
        position: relative;
        padding-top: 80px;
        height: 430px;
    }

    &-Left {
        flex: 0 0 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .h1{ 
            font-family : "Impact", 'Roboto', sans-serif;
            font-weight: 900;
            font-size: 56px;
            color: rgba(229, 64, 11, 1);
            text-shadow: 
            -2px -2px 0 black, 
            2px -2px 0 black,
            -2px  2px 0 black, 
            4px  4px 0 black;  

            @include breakpoint-mobile-only{
                font-size: 36px;
            }                 
        }

        @include breakpoint-mobile-only{
            padding-top: 30px;
            height: 70%;
        }

        
        @include breakpoint-tablet-only {
            position: absolute;
            top: 80%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            padding: 10px;
            text-align: center;
            width: 100%;

            .h1{
                font-size: 36px;
            }
        }
    }

    &-Right {
        flex: 0 0 80%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint-mobile-only{
            align-items: flex-start;
            padding-top: 30px;
        }
        img {
            border: 15px solid white;
        }

        .image-1 {
            margin-top: 30px;
            max-width: 288px;
            height: auto;
            transform: rotate(-3.73deg);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
            
            @include breakpoint-tablet-only{
                width: 230px;
            }

            @include breakpoint-mobile-only{
                width: 160px;
                margin-top: 230px;
            }
        }

        .image-2 {
            max-width: 337px;
            height: auto;
            margin-right: -30px;
            margin-left: -30px;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);

            @include breakpoint-tablet-only{
                width: 260px;
            }

            @include breakpoint-mobile-only{
                margin-right: -130px;
                margin-left: -130px;
                width: 260px;
            }
        }

        .image-3 {
            margin-top: 30px;
            max-width: 283px;
            height: auto;
            transform: rotate(3.73deg);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
            z-index: 2;
            @include breakpoint-tablet-only{
                width: 230px;
            }

            @include breakpoint-mobile-only{
                width: 160px;
                margin-top: 230px;
            }
        }

        .bigbytes-tomato{
            position:absolute;
            width: 150px;
            z-index: 1;
            right: 0px;
            border: none;
            cursor: pointer;
            visibility: hidden;

            @include breakpoint-tablet-only{
                right: -70px;
            }

            @include breakpoint-mobile-only{
                bottom: 30px;
                right: -60px;
            }
        }
    }
}

    }

    @include breakpoint-desktop-up{
        .BodySection::before {
        content: ""; 
        position: absolute; 
        top: 0; 
        left: 35px; 
        width: 20px; 
        height: 100%; 
        background-color: white; 
        }

        .BodySection::after {
            content: ""; 
            position: absolute; 
            top: 0; 
            left: 0px; 
            width: 35px; 
            height: 100%; 
            background-color: rgba(0, 237, 126, 1);
        }
    }
    

    .BigBytesBannerBottom{
        background: white;
        height: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
        max-width:100%;
        overflow: hidden;

        @include breakpoint-mobile-only{
            position: relative;
        }

        &-Text{
            font-family : "Impact", 'Roboto', sans-serif;
            font-weight: 900;
            font-size: 50px;
            text-align: center;
            color: rgba(229, 64, 11, 1);
            white-space: nowrap;
            text-shadow: 
            -2px -2px 0 black, 
            2px -2px 0 black,
            -2px  2px 0 black, 
            4px  4px 0 black;

            span{
                margin-right: 20px;
            }
            @include breakpoint-tablet-down{
                font-size: 44px;
                display: block;
            }

            @include breakpoint-tablet-only{
                font-size: 40px;
            }

            @include breakpoint-mobile-only{
                font-size: 32px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                width: 100%;
                white-space: normal ;
                line-height: 34px;
                text-shadow: 
                    -2px -2px 0 black, 
                    2px -2px 0 black,
                    -2px  2px 0 black, 
                    4px  4px 0 black;
                display: flex;
                flex-direction: column;
                span{
                    margin-right: 0px;
                }
            }
        }
        &-Left{
            width: 50%;
            height: 68px; 
            background-image: url(#{$IMAGE_DIR}tile.svg);
            background-repeat: repeat; 
            background-size: auto; 

            @include breakpoint-mobile-only{
                display: none;
            }
        }

        &-Right{
            width: 23%; 
            height: 68px; 
            background-image: url(#{$IMAGE_DIR}tile.svg);
            background-repeat: repeat; 
            background-size: auto; 

            @include breakpoint-tablet-only{
                width: 55%;
            }

            @include breakpoint-mobile-only{
                width: 100%;
            }
        }

    }
    #splatHolder {
        @include FULL_COVER;
        pointer-events: none;
        user-select: none;
    }
    .pizza-sequence{
            // Initially hide
            visibility: hidden;
            pointer-events: none;
            user-select: none;
            position: absolute;
            width: 650px;
            height: 650px;
            margin-top: -30px;
            z-index: 4;
            top: 569px;
            @include breakpoint-tablet-only{
                width: 418px;
                height: 418px;
                top: 469px;
            }

            @include breakpoint-mobile-only{
                width: 250px;
                height: 250px;
                top: 329px;
            }
        }
}
